import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as config from 'config/services/requests'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eElementOnMouseOverEffect, eLinkTarget } from 'components/types'

import { RoutePaths } from 'routes/paths'
import { Apps } from '@mui/icons-material'
import {
  isDrawerOpenReducerState,
  toggleDrawer,
} from 'store/slices/generalSettings'

interface IProps {
  logo?: string
  children?: JSX.Element | JSX.Element[]
}

const ApplicationBar = ({ logo, children }: IProps) => {
  /**
   */
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isMobile, setMobile] = useState(true)
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))
  const isDrawerOpen: boolean =
    useSelector(isDrawerOpenReducerState).isDrawerOpen || false
  const [hamburgerColor, setHamburgerColor] = useState<string>('')
  useEffect(() => {
    if (isDrawerOpen) {
      setHamburgerColor('fill-[color:var(--paletteYellow)]')
    } else {
      setHamburgerColor('')
    }
  }, [isDrawerOpen])

  // TO DO : This needs update

  useEffect(() => {
    !isBelowMd && isMobile ? setMobile(false) : setMobile(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBelowMd])

  return (
    <div className="AppBar flex items-start border-b-[0.0625rem] border-[color:var(--paletteLightGrayDelimiter)] z-50 dark:border-[color:var(--paletteDarkGrayDelimiter)]">
      <div className="p-3 sm:flex sm:inline-flex lg:hidden">
        <Apps
          onClick={() => {
            dispatch(toggleDrawer())
          }}
          className={`sm:p-0 sm:m-0 ${hamburgerColor}`}
        />
      </div>
      <div
        className="flex w-full h-[48px] sm:pl-0 lg:pl-5 sm:justify-center sm:items-center sm:align-middle lg:align-start lg:justify-start"
        // variant={`dense`}
        // sx={{
        //   minWidth: '9.375rem',
        //   flexBasis: '100%',
        //   '@media (min-width: 37.5rem)': {
        //     paddingRight: '0.75rem',
        //     paddingLeft: '4rem',
        //   },
        // }}
      >
        <img
          src={config.assets.logo}
          className={`link h-7 max-md:h-6`}
          alt={`sustamize logo`}
          onClick={() => navigate(RoutePaths.dashboard)}
        />
      </div>
      <div className={`text-right w-[15%] sm:pr-10 lg:pr-20 sm:pt-2 lg:pt-4`}>
        <LinkWrapper
          className="px-2"
          target={eLinkTarget.blank}
          to={RoutePaths.knowledgeHubDatabaseOverview}
          underline={eElementOnMouseOverEffect.hover}
        >
          {'V2.4.0'}
        </LinkWrapper>
      </div>
    </div>
  )
}

export default ApplicationBar
