import LinkWrapper from 'components/custom/LinkWrapper'
import { eLinkTarget } from 'components/types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface iProps {
  label: string
  data: number | string
}

const DataWithLabel = ({ label, data }: iProps) => {
  const { t } = useTranslation()

  const contentGenerator = useCallback(() => {
    if (data) {
      if (data.toString().startsWith('http')) {
        const longUrl = data.toString().length > 100
        return (
          <LinkWrapper
            to={data.toString()}
            target={eLinkTarget.blank}
            title={data.toString().length > 100 ? data.toString() : undefined}
          >
            {`${data.toString().substr(0, 100)}${longUrl ? ' ...' : ''}`}
          </LinkWrapper>
        )
      } else {
        return <span>{data ? t(data.toString()) : t('general.na')}</span>
      }
    }
  }, [data, t])

  return (
    <div className="row">
      <label htmlFor={label}>{label}:</label>
      {contentGenerator()}
    </div>
  )
}

export default DataWithLabel
