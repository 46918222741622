import { iLooseObject } from 'pages/DataHub/filters/types'

interface iIdentification {
  id: string
  sid: string
  title: string
  description: string
}
interface iValidity {
  addedOn: string
  lastUpdatedOn: string
  lifecycleStage: number
}
interface iInfoGeneral {
  descriptionDe: string
  descriptionEn: string
  oldName: string
  ukName: string
  usName: string
}

interface iCo2BaseCommon {
  min: number | null
  max: number | null
  mean: number | null
  median: number | null
  range: number | null
  common: number | null
}
interface iCo2BaseRecycle {
  common: number | null
  min: number | null
  max: number | null
  mean: number | null
  median: number | null
  range: number | null
  recycled: number | null
}

interface iCo2MetalUnitRange {
  unit: string
  rangeFrom: number
  rangeTo: number
}
// Material Technical Data
interface iCo2SintersTechnicalData {
  density: { value: number; unit: string }
  emissionFactorPrimary: number
  emissionFactorUnitSymbol: string
  rx: { value: string; unit: string }
  strength: string
}

interface iCo2MetalTechnicalData {
  density: { value: number; unit: string }
  emissionFactorPrimary: number
  emissionFactorRecycling: number
  emissionFactorUnitSymbol: string
  maximumElongation: { value: number; unit: string }
  maximumElongationRange: iCo2MetalUnitRange
  ruptureStrength: { value: number; unit: string }
  ruptureStrengthRange: iCo2MetalUnitRange
  wnrEu: string
  yieldStrength: { value: number; unit: string }
}

// Production Technical Data

interface iCo2EquipmentTechnicalData {
  additionalInvestment: number
  consumableRate: number
  emissionFactorPrimary: number
  emissionFactorUnitSymbol: string
  equipmentFloorSize: number
  equipmentId: string
  equipmentInvestment: number
  floorSizeUnit: string
  electricConsumption: number
  electricConsumptionUnit: string
  fullLoadRate: number
  fullLoadRateUnit: string
  fundamentInvestment: number
  investmentCurrencyUnit: string
  investmentRemark: string
  maintenanceRate: number
  setupInvestment: number
  workspaceFloorSize: number
}

interface iCo2ManufacturingData {
  efficiencyCoefficient: number
  efficiencyCoefficientUnit: string
  energyConsumption: number
  energyConsumptionUnit: string
  group: number
  hierarchy: string
  type: string
}

export interface iCo2Source {
  title: string
  addedOn: string
  lastUpdatedOn: string
  descriptionEn: string
  descriptionDe: string
  quality: string
  validity: string
  classification: string
  authors: string
  editors: string
  url: string
}

export interface iCo2Process {
  mathematicalDescription: string
}

export interface iCo2Origin {
  descriptionEn: string
  descriptionDe: string
  commentsEn: string
  commentsDe: string
}

export interface iCo2Values {
  id: string
  referenceValue: number
  referenceValueRange: {
    rangeFrom: number
    rangeTo: number
  }
  validity: {
    rangeFrom: string
    rangeTo: string
  }
  addedOn: string
  isCommon: boolean
  isRecycled: boolean
  measurementCalculationFactor: number
  scope: eScope
  co2Source: iCo2Source
  co2Origin: iCo2Origin
  calculationProcessModel: iCo2Process
}

export interface iCo2ValuesChipLabel {
  label: string
  color: string
}

export enum eCo2Details {
  sources = 'co2Sources',
  process = 'calculationProcessModel',
}

export enum eScope { 
  TANK_TO_WHEEL = 'tank-to-wheel',
  WELL_TO_WHEEL  = 'well-to-wheel',
  WELL_TO_TANK = 'well-to-tank',
  CRADLE_TO_CRADLE  = 'cradle-to-cradle',
  CRADLE_TO_GATE = 'cradle-to-gate',
  CRADLE_TO_GRAVE = 'cradle-to-grave',
  GATE_TO_GATE = 'gate-to-gate'
}

export interface iCo2Emission {
  energyProduction: iEnergyProduction
  country: string
  validityPeriod: {
    rangeFrom: string
    rangeTo: string
  }
  packagingMaterialCo2Value?: number
  packagingMaterialCo2ValueUnit?: string
  emissionFactorUnitSymbol: string
  emissionFactorRecycling: number
  emissionFactorPrimary: number
  emissionFactorPrimaryTotal: number
  oldestRecord: string
  newestRecord: string
  totalRecords: number
  deliveryShape: string
  packagingReference: string
  packagingThickness: string
  emissionPerUnit: string
  emissionFactorUptake: number
  emissionWithoutUptake: number
  referenceUnit: string
  averageRecordQuality: number
  co2Base: iCo2BaseCommon
  co2Recycling: iCo2BaseRecycle
  co2Values: iCo2Values[]
}

export interface iCo2EmissionAccordionColumn {
  key: string
  value: number | string
}

//Energy Instance Data
export interface iEnergyProduction {
  nonRenewableEnergy: {
    totalNonRenewableEnergy: string
    coal: string
    oil: string
    naturalGas: string
    otherCombustibles: string
  }
  conventionalThermalEnergy: {
    totalConventionalThermalEnergy: string
    nuclear: string
  }
  renewableEnergy: {
    totalRenewableEnergy: string
    hydro: string
    wind: string
    solar: string
    geothermal: string
    otherRenewables: string
  }
  nonSpecifiedEnergy?: {
    totalNonSpecifiedEnergy: string
    nonSpecified: string
  }
}
export interface iCo2EnergyInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { code: string }
    identification: iInfoGeneral
    technical: {
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
      ieaDesignation: string
    }
    validity: iValidity
  }
  energyProduction: iEnergyProduction
}

// Materials Instance Data
export interface iCo2CompositesInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { shortName: string }
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorRecycling: number
      emissionFactorUnitSymbol: string
    }
    validity: iValidity
  }
}

export interface iCo2ConstructionInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { remark: string }
    identification: iIdentification
    technical: {
      dimensionInformation: string
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
    }
    validity: iValidity
  }
}

export interface iCo2MetalInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: iInfoGeneral
    identification: iIdentification
    validity: iValidity
    technical: iCo2MetalTechnicalData
  }
}

export interface iCo2ElementsInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: iInfoGeneral
    identification: iIdentification
    technical: {
      dataType: string
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
      periodicTableSymbol: string
    }
    validity: iValidity
  }
}
export interface iCo2PolymersInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { fullName: string; shortName: string }
    identification: iIdentification
    technical: {
      density: { unit: string; value: number }
      emissionFactorPrimary: number
      emissionFactorRecycling: number
      emissionFactorUnitSymbol: string
      glassTemperature: { unit: string; value: number }
    }
    validity: iValidity
  }
}

export interface iCo2SintersInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: {
      fullNameDe: string
      fullNameEn: string
      shortName: string
    }
    identification: iIdentification
    technical: iCo2SintersTechnicalData
    validity: iValidity
  }
}

//Production Instance Data

export interface iCo2EmployeesInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: {}
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
    }
    validity: iValidity
  }
}

export interface iCo2EquipmentInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { classification: string; remark: string; vendor: string }
    identification: iIdentification
    technical: iCo2EquipmentTechnicalData
    validity: iValidity
  }
}

export interface iCo2ManufacturingInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { alternativeDescription: string }
    identification: iIdentification
    technical: iCo2ManufacturingData
    validity: iValidity
  }
}

// Logistics Instance Data
export interface iCo2PackagingInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { packagingClass: string }
    identification: iIdentification
    technical: {
      missionFactorPrimary: number
      emissionFactorUnitSymbol: string
      packagingMaterials: { 'pE-LD': string }
    }
    validity: iValidity
  }
}

export interface iCo2TransportationInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: { remark: string; type: string }
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
      loadRatio: number
    }
    validity: iValidity
  }
}

export interface iSingleEntityInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: {
      shortName: string
    }
    identification: iIdentification
    technical: {}
    validity: iValidity
  }
}

export interface iCo2SingleEntity {
  primaryColumn: {
    title: { name: string; unit?: string }
    primary: iLooseObject
  }
  recyclingColumn?: {
    title: { name: string; unit?: string }
    recycling?: iLooseObject
  }
  infoColumn?: {
    title?: string
    information?: iLooseObject
  }
  totalColumn?: {
    title?: string
    data?: iLooseObject
  }
}
export interface iCO2AccordionIcon {
  icon: string
  value: string
  title: string
  unit?: string
}
export interface iCo2AccordionEntity {
  country?: iCO2AccordionIcon
  emissionPerUnit?: iCO2AccordionIcon
  emissionPrimary?: iCO2AccordionIcon
  emissionRecycling?: iCO2AccordionIcon
  deliveryShape?: iCO2AccordionIcon
  uptakeConstruction?: iCO2AccordionIcon
  co2PerUnit?: iCO2AccordionIcon
  equipmentInvestment?: iCO2AccordionIcon
  validityPeriod?: iCO2AccordionIcon
}

export interface iSingleEntityInstance {
  instance: iSingleEntityInstanceData
}
export interface iSingleEntity {
  entity: iSingleEntityInstance
}
export interface iSingleEntityState {
  singleEntity: iSingleEntity | null
}
