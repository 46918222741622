import {
  iContextItem,
  iContextMetal,
  iContextPolymer,
} from 'store/types/massFileUpload'
import { iSingleEntityParams } from '../requests/dataHub/types'

/**
 * For building the string that sets up the search request properly
 * @param context The context string of the search API
 * @param searchTerms The search terms as a string or an array of search terms
 * @param contextItem The context item, containing additional params for the request
 * @returns
 */
export const buildSearchRequestUri = (
  context: string,
  searchTerms: string | string[],
  contextItem?: iContextItem | iContextMetal | iContextPolymer
): string => {
  let urlBase = 'results'
  let contextNameParam = `contextName=${context}`

  let terms: string = Array.isArray(searchTerms)
    ? searchTerms.join()
    : searchTerms

  let termsParam = `terms=${terms}`
  let additionalParams = contextItemToUriParams(contextItem)

  return `${urlBase}?${contextNameParam}&${termsParam}${additionalParams}`
}

/**
 * Build the proper URI layout for requesting a single entity
 * @deprecated Request to Single Entity API is now done using object, not URI string
 * @param uuid The uuid of the entity
 * @param context The database context in which to search the entity
 * @param contextItem The object containing the URI params for searching
 * @returns
 */
export const buildSingleEntityRequestUri = (
  uuid: string,
  context: string,
  contextItem: iContextItem | iContextMetal | iContextPolymer
): string => {
  let contextNameParam = `contextName=${context}`
  let additionalParams = contextItemToUriParams(contextItem)

  return `${uuid}?${contextNameParam}${additionalParams}`
}

/**
 * Transforms a contextItem into URI params for the search API request
 * @param contextItem The row item of a worksheet as an object
 * @returns An URI string with params, according to the schema of the context item
 */
const contextItemToUriParams = (
  contextItem?: iContextItem | iContextMetal | iContextPolymer
): string => {
  if (!contextItem) return ''
  let uriParams: string = ''

  Object.entries(contextItem).forEach(([key, value]) => {
    /** Filter out attributes we don't need on the request */
    if (key === 'rowNumber' || key === 'terms') return

    /** Concatenate others */
    uriParams += `&${key.toString()}=${value.toString()}`
  })

  return uriParams
}

/**
 * The function for preparing the params object when requesting a single entity
 * @param uuid The UUID of the single entity requested
 * @param contextName The context in which to look for the UUID
 * @param contextItem The additional query parameter values, coming from the Excel context objects
 */
export const buildSingleEntityRequestObject = (
  uuid: string,
  contextName: string,
  contextItem: iContextItem | iContextMetal | iContextPolymer
): iSingleEntityParams => {
  /** Verify type expansion */
  let hasReferenceType = Object.keys(contextItem).includes('referenceType')
  let hasDeliveryShape = Object.keys(contextItem).includes('deliveryShape')

  /** Populate the object and return */
  let params: iSingleEntityParams = {
    uuid: uuid,
    contextName: contextName,
    countries: contextItem.country,
    isRecycled: contextItem.isRecycled,

    referenceType: hasReferenceType
      ? (contextItem as iContextPolymer).referenceType
      : undefined,

    deliveryShape: hasDeliveryShape
      ? (contextItem as iContextMetal).deliveryShape
      : undefined,
  }

  return params
}
