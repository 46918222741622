import ContentBodyWrapper from "components/custom/ContentBodyWrapper";
import PaperWrapper from "components/custom/PaperWrapper";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { eAdditionalDatabaseSelection, eBillingPeriod, ePackageSelection, ePricingMode } from "components/types";
import { PricingModeBigTab } from "./components/pricingModeBigTab";
import { BillingPeriodSwitcher } from "./components/billingPeriodSwitcher";
import { PackageOffering } from "./components/packageOffering";
import { PackageOfferingPrice } from "./components/packageOffering/packageOfferingPrice";
import { PackageOfferingFeatures } from "./components/packageOffering/packageOfferingFeatures";
import { PackageOfferingAction } from "./components/packageOffering/packageOfferingAction";
import { fallbackFunctionForUpgradeScenario, getOfferingsByType } from "config/offerings";
import { Typography } from "@mui/material";
import { iOffering } from "store/types/billingPricing";
import { OrderForm } from "../OrderForm";
import { useDispatch, useSelector } from "react-redux";
import { organizationProfileState } from "store/slices/organizationProfile";
import { iOrganizationData } from "store/types";
import { eProductKey } from "store/types/subscriptionOrder";
import { userReducerState } from "store/slices/user";
import { sagaGetOrganizationProfileDataAction } from "saga/actions/user";
import { resetState } from "store/slices/subscriptionOrderSlice";
import { getAddOnsDatabasesFromSubscriptions, getPackageProductKeyFromSubscriptions, isBillingRestricted } from "../helpers";
import { packageSelectionToPackageKey } from "../OrderForm/helpers";
import { RoutePaths } from "routes/paths";
import { useNavigate } from "react-router-dom";

interface iPricingProps {
  alternativeTitle?: string
}

export const Pricing: FC<iPricingProps> = ({
  alternativeTitle
}) => {

  const { t } = useTranslation()
  let navigate = useNavigate()

  /** Organization */
  const dispatch = useDispatch()
  const { organization, roles } = useSelector(userReducerState)
  useEffect(() => {
    organization && dispatch(sagaGetOrganizationProfileDataAction())
  }, [organization, dispatch])
  const organizationData: iOrganizationData = useSelector(
    organizationProfileState
  )

  const [pricingMode, setPricingMode] = useState<ePricingMode>(ePricingMode.COMMERCIALS)
  const [billingPeriod, setBillingPeriod] = useState<eBillingPeriod>(eBillingPeriod.YEARLY)

  const [isOrderFormOpen, setIsOrderFormOpen] = useState<boolean>(false)
  // const [isUpgradeFormOpen, setIsUpgradeFormOpen] = useState<boolean>(false)
  const [packageSelection, setPackageSelection] = useState<ePackageSelection>(ePackageSelection.FLEX)
  const [additionalDatabaseSelection, setAdditionalDatabaseSelection] =
    useState<eAdditionalDatabaseSelection|undefined>()

  const [isOfferingReady, setIsOfferingReady] = useState<boolean>(false)

  const getOfferingStringFromLabel = (label: string) => label.replace(/\.[a-zA-Z]+$/, '')

  const getPackageSelectionByOfferingString = (offeringString: string): ePackageSelection => {
    if (offeringString.endsWith('.standard')) {
      return ePackageSelection.STANDARD
    } else if (offeringString.endsWith('.pro')) {
      return ePackageSelection.PRO
    } else {
      return ePackageSelection.FLEX
    }
  }

  const setPackageSelectionFromPackageKey = (key?: eProductKey) => {
    switch (key) {
      case eProductKey.SUST_STANDARD_PACKAGE:
        setPackageSelection(ePackageSelection.STANDARD)
        break
      case eProductKey.SUST_PRO_PACKAGE:
        setPackageSelection(ePackageSelection.PRO)
        break
      default:
        setPackageSelection(ePackageSelection.FLEX)
    }
  }

  /** Set Organization Package Selection */
  useEffect(() => {
    const subscriptions = Object.keys(organizationData).includes('subscriptions')
      ? organizationData.subscriptions
      : undefined
      setIsOfferingReady(true)
    if (!subscriptions) return

    const packageKey = getPackageProductKeyFromSubscriptions(subscriptions)
    setPackageSelectionFromPackageKey(packageKey)

  }, [organizationData, isOfferingReady])

  const handleActionClick = useCallback(
    (offering: iOffering) => {
      
      if (!isBillingRestricted(roles)?.length && !!organizationData?.id ) {
        navigate(RoutePaths.memberAccessForbidden)
        return
      }

      const subscriptions = Object.keys(organizationData).includes(
        'subscriptions'
      )
        ? organizationData.subscriptions
        : undefined

      setAdditionalDatabaseSelection(undefined)
      const offeringString = getOfferingStringFromLabel(offering.label)

      if (
        Object.values<any>(eAdditionalDatabaseSelection).includes(
          offeringString
        )
      ) {
        setAdditionalDatabaseSelection(
          offeringString as eAdditionalDatabaseSelection
        )
      }

      const numOfActiveSubscriptions = subscriptions
        ? subscriptions?.filter((s: any) => s.active && !s.canceled).length
        : 0

      const subscriptionKey =
        getPackageProductKeyFromSubscriptions(subscriptions)

      if (numOfActiveSubscriptions > 0) {
        setPackageSelectionFromPackageKey(subscriptionKey)
      } else {
        const userPackageSelection =
          getPackageSelectionByOfferingString(offeringString)
        setPackageSelection(userPackageSelection)
      }

      if (offering.action === 'offerings.upgrade') {
        fallbackFunctionForUpgradeScenario()
      } else {
        // setIsUpgradeFormOpen(true)
        setIsOrderFormOpen(true)
      }
    },
    [organizationData, roles, t, navigate]
  )

  const handleCloseModal = () => {
    setIsOrderFormOpen(false)
    dispatch(resetState())
  }

  const renderOffering = useCallback((o: iOffering, i: number) => {
    const offeringSelection: ePackageSelection = getOfferingStringFromLabel(o.label) as ePackageSelection
    const offeringsKey = packageSelectionToPackageKey(offeringSelection)
    const organizationSelection = getPackageProductKeyFromSubscriptions(organizationData.subscriptions)
    const includedProductKeys = getAddOnsDatabasesFromSubscriptions(
      organizationData.subscriptions
    )

    if (
      o.addOnDatabaseKey &&
      includedProductKeys.includes(o.addOnDatabaseKey)
    ) {
      o.action = 'offerings.addMore'
    }

    if (!!organizationSelection && offeringsKey === organizationSelection) {
      o.action = 'offerings.addMore'
    } else if (
      organizationSelection === eProductKey.SUST_STANDARD_PACKAGE &&
      offeringSelection === ePackageSelection.PRO
    ) {
      o.action = 'offerings.upgrade'
    } else if (
      organizationSelection === eProductKey.SUST_STANDARD_PACKAGE &&
      offeringSelection === ePackageSelection.FLEX
    ) {
      o.action = 'offerings.downgrade'
      o.isActionDisabled = true
    } else if (
      organizationSelection === eProductKey.SUST_PRO_PACKAGE &&
      (offeringSelection === ePackageSelection.STANDARD ||
        offeringSelection === ePackageSelection.FLEX)
    ) {
      o.action = 'offerings.downgrade'
      o.isActionDisabled = true
    } 

    return (
      <PackageOffering
        key={i}
        title={o.label}
        elevated={o.isElevated}
        labelColor={o.labelColor}
        description={o.description}
        labelIcon={o.labelIcon}
      >
        <PackageOfferingPrice
          prefix={o.price.prefix}
          pricing={o.price.pricing}
          suffix={o.price.suffix}
          billingPeriod={billingPeriod}
          mode={pricingMode}
        />

        <PackageOfferingFeatures 
          features={o.features} 
          descriptionHeading={o.descriptionHeading}
          description={o.description}
          isMultipleColumns={o.isFeaturesMultipleColumns}
        />

        <PackageOfferingAction 
          disabled={o.isActionDisabled}
          color={o.actionColor}
          label={o.action}
          action={o.actionFunction || (() => handleActionClick(o))}
        />
      </PackageOffering>
  )
}, [organizationData, billingPeriod, pricingMode, handleActionClick])

  return (
    <>
    <ContentBodyWrapper title={t(alternativeTitle || `pages.pricing`)}>
      <PaperWrapper>
        <PricingModeBigTab 
          pricingMode={pricingMode}
          setPricingMode={setPricingMode}
        />

        {pricingMode === ePricingMode.COMMERCIALS &&
          <BillingPeriodSwitcher
            billingPeriod={billingPeriod}
            setBillingPeriod={setBillingPeriod}
          />
        }

        <div className={`flex flex-col lg:flex-row justify-evenly lg:justify-center content-between gap-4 flex-nowrap`}>
          {Object.values(getOfferingsByType(pricingMode))
            .map((o, i) => renderOffering(o, i))}
        </div>

        {pricingMode === ePricingMode.COMMERCIALS &&

          <div className={``}>

            <Typography variant="h5" color={`primary`}>
              {t('offerings.additionalDatabases')}
            </Typography>

            <div className={`flex flex-col lg:flex-row justify-evenly lg:justify-center content-between gap-4 flex-nowrap`}>
              {isOfferingReady &&
                Object.values(getOfferingsByType('generic').map((o, i) => {
                  return renderOffering(o, i);
                }))}
            </div>
        
          </div>
        }

      </PaperWrapper>
    </ContentBodyWrapper>

    <OrderForm 
      open={isOrderFormOpen} 
      onClose={handleCloseModal} 
      packageSelection={packageSelection}
      additionalDatabaseSelection={additionalDatabaseSelection}
      billingPeriod={billingPeriod}
      resetOrderFormStatus={!isOrderFormOpen}
    />
    </>  
  )
}