import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CancelIcon from '@mui/icons-material/Cancel'

import './SingleEntityModal.css'
import SearchBar from 'components/custom/SearchBar'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Typography } from '@mui/material'
import SingleEntityDetails from './SingleEntityDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setVisibility } from 'store/slices/entityCO2Data'
import { eCo2Details, iCo2Emission, iCo2Values } from 'store/types'
import { susIcons } from 'config/services/requests'
import { inArray, getUrlParamValue, urlToObject } from 'config/lib'
import SingleEntityCard from './SingleEntityCard'
import { useLocation, useSearchParams } from 'react-router-dom'
import { singleEntityState } from 'store/slices/singleEntity'
import { iLooseObject } from 'pages/DataHub/filters/types'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eButtonVariant } from 'components/types'

interface iProps {
  cO2Values: iCo2Values[]
  emission: iCo2Emission
}

export default function SingleEntityModal({ cO2Values, emission }: iProps) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)
  const [, setSearchParams] = useSearchParams()
  const [notFound, setNotFound] = useState(false)
  const [cardValues, setCardValues] = useState<iCo2Values[]>([])
  const singleEntity =
    useSelector(singleEntityState)?.singleEntity?.entity?.instance
  const [searchInputValue, setSearchInputValue] = useState('')

  const onSearch = useCallback(
    (searchTerm: string) => {
      const tempCardsValues = cO2Values.filter(
        (item: iCo2Values, index: number) => {
          return inArray(
            [
              item.addedOn,
              item.referenceValue.toString(),
              emission.validityPeriod.rangeFrom.toString(),
              emission.validityPeriod.rangeTo.toString(),
            ],
            searchTerm.split(' ')
          )
        }
      )

      setNotFound(tempCardsValues.length === 0)
      setCardValues(tempCardsValues)
      //set url param with search terms:
      singleEntityParams['search'] = searchTerm.split(' ').join(',')
      setSearchParams(singleEntityParams)
    },
    [
      cO2Values,
      emission.validityPeriod.rangeFrom,
      emission.validityPeriod.rangeTo,
      setSearchParams,
      singleEntityParams,
    ]
  )

  const id = getUrlParamValue(singleEntityParams, 'id') || ''
  const co2DataisVisible: boolean = !id

  // set data in the page from URL values
  useEffect(() => {
    const urlParamsMatch =
      singleEntityParams['country'] === emission.country &&
      singleEntityParams['validityFrom'] ===
        emission.validityPeriod.rangeFrom &&
      singleEntityParams['validityTo'] !== emission.validityPeriod.rangeTo

    if (urlParamsMatch) {
      setCardValues(cO2Values)
    }
  }, [
    cO2Values,
    emission.country,
    emission.validityPeriod.rangeFrom,
    emission.validityPeriod.rangeTo,
    setCardValues,
    singleEntityParams,
  ])

  useEffect(() => {
    const emissionCountry = emission.country
    const emissionValidityFrom = emission.validityPeriod.rangeFrom
    const emissionValidityTo = emission.validityPeriod.rangeTo
    const searchTerms = getUrlParamValue(singleEntityParams, 'search') as string

    if (
      getUrlParamValue(singleEntityParams, 'country') === emissionCountry &&
      getUrlParamValue(singleEntityParams, 'validityFrom') ===
        emissionValidityFrom &&
      getUrlParamValue(singleEntityParams, 'validityTo') ===
        emissionValidityTo &&
      getUrlParamValue(singleEntityParams, 'search') !== searchInputValue
    ) {
      if (searchTerms) onSearch(searchTerms)
      setOpen(true)
    }
  }, [
    emission.country,
    emission.validityPeriod.rangeFrom,
    emission.validityPeriod.rangeTo,
    onSearch,
    searchInputValue,
    singleEntityParams,
  ])
  const DataPointIcon = susIcons.DataPoints

  const handleClickOpen = useCallback(() => {
    singleEntityParams['country'] = emission.country
    singleEntityParams['validityFrom'] = emission.validityPeriod.rangeFrom
    singleEntityParams['validityTo'] = emission.validityPeriod.rangeTo
    setSearchParams(singleEntityParams)
    setOpen(true)
    setCardValues(cO2Values)
  }, [
    cO2Values,
    emission.country,
    emission.validityPeriod.rangeFrom,
    emission.validityPeriod.rangeTo,
    setSearchParams,
    singleEntityParams,
  ])

  const handleClose = useCallback(() => {
    delete singleEntityParams['country']
    delete singleEntityParams['validityFrom']
    delete singleEntityParams['validityTo']
    delete singleEntityParams['search']
    delete singleEntityParams['type']
    setSearchParams(singleEntityParams)
    setOpen(false)
    setNotFound(false)
    dispatch(setVisibility(false))
  }, [dispatch, setSearchParams, singleEntityParams])

  const onCO2Click = useCallback(() => {
    onSearch('')

    delete singleEntityParams['id']
    delete singleEntityParams['type']
    delete singleEntityParams['search']
    setSearchParams(singleEntityParams)
  }, [onSearch, setSearchParams, singleEntityParams])

  const valueType = getUrlParamValue(singleEntityParams, 'type')

  const getModalTitle = useCallback(() => {
    switch (valueType) {
      case eCo2Details.process:
        return t('singleEntity.card.process')
      case eCo2Details.sources:
        return t('singleEntity.card.externalSources')
      default:
        return t('singleEntity.CO2Values.titleModal')
    }
  }, [t, valueType])

  const getSearchBar = useCallback(() => {
    return (
      <SearchBar
        placeholderProp={t('general.search')}
        onSearch={(value) => {
          onSearch(value || '')
          setSearchInputValue(value || '')
        }}
        customValue={getUrlParamValue(singleEntityParams, 'search') || ''}
        label={t('general.searchTopicInput', {
          topic: t('singleEntity.title'),
        })}
      />
    )
  }, [onSearch, singleEntityParams, t])

  return (
    <React.Fragment>
      <div>
        <ButtonWrapper
          className="flex self-center"
          variant={eButtonVariant.outlined}
          onClick={handleClickOpen}>
          {t('singleEntity.showCO2Values')}
        </ButtonWrapper>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        className="singleEntity-container"
        onClose={handleClose}>
        <DialogActions>
          <CancelIcon className="cursor-pointer" onClick={handleClose} />
        </DialogActions>
        <DialogContent className="pt-0">
          <DialogContentText component={'div'}>
            <div className="breadcrumb-container">
              <h2>{getModalTitle()}</h2>
              <Breadcrumbs
                separator=">"
                aria-label="breadcrumb"
                className="breadcrumb pl-2"
                sx={{ marginBottom: '2.1875rem' }}>
                <span className="cursor-pointer" onClick={() => handleClose()}>
                  {singleEntity?.information?.identification?.title}
                </span>

                <span className="cursor-pointer" onClick={() => onCO2Click()}>
                  {t('singleEntity.CO2Values.titleModal')}
                </span>

                {valueType && (
                  <span className="cursor-pointer">
                    {valueType === eCo2Details.sources &&
                      t('singleEntity.card.externalSources')}
                    {valueType === eCo2Details.process &&
                      t('singleEntity.card.process')}
                  </span>
                )}
              </Breadcrumbs>
              <div className='ml-4'>
              {co2DataisVisible ? getSearchBar() : null}
              </div>
            </div>
            {[eCo2Details.sources, eCo2Details.process].indexOf(
              getUrlParamValue(singleEntityParams, 'type')
            ) !== -1 ? (
              <SingleEntityDetails cO2Values={cO2Values} />
            ) : notFound ? (
              <div className="notFound-layout">
                <Typography variant="h4">{t('search.noResults')}</Typography>
                <DataPointIcon />
              </div>
            ) : (
              <div className="flex flex-wrap justify-start gap-x-20 gap-y-3.5 pt-7 mx-4 mb-4">
                {cardValues.map((cardValue, index: number) => (
                  <SingleEntityCard
                    cO2Values={cardValue}
                    emissionFactorUnitSymbol={emission.emissionFactorUnitSymbol}
                    key={index}
                  />
                ))}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
