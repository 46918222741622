import React from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { iCo2Source } from 'store/types'
import DataWithLabel from './DataWithLabel'

import './SingleEntityDetails.css'

interface iProps {
  data: iCo2Source[]
}

const SingleEntityCo2SourceDetails = ({ data }: iProps) => {
  const { t } = useTranslation()

  return (
    <>
      {data.map((co2Source, index) => {
        return (
          <Paper
            key={index + co2Source?.title}
            className="singleEntity-dialog-wrapper">
            <div className="group">
              <DataWithLabel
                label={t('singleEntity.details.title')}
                data={co2Source?.title}
              />
            </div>
            <div className="group">
              <DataWithLabel
                label={t('singleEntity.details.lastUpdated')}
                data={co2Source?.lastUpdatedOn}
              />
            </div>
            <div className="group">
              <DataWithLabel
                label={t('singleEntity.details.quality')}
                data={co2Source?.quality}
              />
            </div>
            <div className="group">
              <DataWithLabel
                label={t('singleEntity.details.validity')}
                data={co2Source?.validity}
              />
            </div>

            <div className="group">
              <DataWithLabel
                label={t('singleEntity.details.authors')}
                data={co2Source?.authors}
              />
            </div>
            {co2Source?.editors?.length > 0 ? (
              <div className="group">
                <DataWithLabel
                  label={t('singleEntity.details.editors')}
                  data={co2Source?.editors}
                />
              </div>
            ) : null}
            {co2Source?.url ? (
              <div className="group">
                <DataWithLabel
                  label={t('singleEntity.details.url')}
                  data={co2Source?.url}
                />
              </div>
            ) : null}
          </Paper>
        )
      })}
    </>
  )
}

export default SingleEntityCo2SourceDetails
