import React, { useCallback } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ContentBodySection } from 'components/pages/components/ContentBody'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './SingleEntityCO2Values.css'
import SingleEntityModal from './SingleEntityModal'
import {
  iCo2AccordionEntity,
  iCo2EmissionAccordionColumn,
  iCo2Emission,
  iCo2SingleEntity,
  iCO2AccordionIcon,
} from 'store/types'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import AccordionColumn from './AccordionColumn'
import clsx from 'clsx'

interface iProps {
  singleEntity: iCo2SingleEntity | undefined
  entityAccordion: iCo2AccordionEntity | undefined
  emission: iCo2Emission
  customKey?: string
}
const SingleEntityCO2Values = ({
  singleEntity,
  entityAccordion,
  emission,
  customKey,
}: iProps) => {
  const { t } = useTranslation()

  const dataConverter = useCallback(
    (data: any) => {
      let columnData: iCo2EmissionAccordionColumn[] = []

      Object.keys(data).forEach((key) => {
        const dataValue = data[key] === undefined || data[key] === null ? t('general.na') : data[key]
        columnData.push({ key: key, value: dataValue })
      })

      return columnData
    },
    [t]
  )

  const getColumIconWithTitle = useCallback(
    (entityAccordion: iCo2AccordionEntity) => {
      return Object.values(entityAccordion).map(
        (iconObject: iCO2AccordionIcon, index) => {
          return (
            <div
              className="flex items-center flex-[1] text-base opacity-85"
              key={iconObject.title + index}
            >
              <CustomSvgStyled
                className="mr-2"
                src={iconObject.icon}
                title={t(iconObject.title)}
              />
              <span>{iconObject.value}</span>
              {iconObject.unit && (
                <span className="ml-1 text-[12px] -mb-2">
                  {iconObject.unit}
                </span>
              )}
            </div>
          )
        }
      )
    },
    [t]
  )

  const getAccordionColumData = useCallback(
    (
      columnTitle: string,
      measuringUnit: string | undefined,
      data: iCo2EmissionAccordionColumn[],
      className?: string
    ) => {
      return (
        <AccordionColumn
          columnTitle={columnTitle}
          measuringUnit={measuringUnit}
          keyPrefix="singleEntity"
          data={data}
          className={className}
        />
      )
    },
    []
  )

  const prepareSingleEntityModal = useCallback(() => {
    return (
      <div className="mt-4 flex justify-center">
        <SingleEntityModal emission={emission} cO2Values={emission.co2Values} />
      </div>
    )
  }, [emission])

  const accordionLength = entityAccordion && Object.keys(entityAccordion).length
  const columnLength = singleEntity && Object.keys(singleEntity).length

  const accordionCSS = clsx(
    'flex flex-wrap w-full gap-y-4',
    accordionLength && accordionLength > 2
      ? 'justify-around max-lg:gap-x-7 max-lg:justify-start'
      : 'justify-evenly gap-x-12 max-lg:gap-x-7'
  )

  const columnCSS = clsx(
    'flex flex-wrap w-full px-4 mt-4',
    columnLength && columnLength > 2
      ? 'justify-between w-[90%] gap-y-4'
      : 'justify-start gap-y-4 max-lg:justify-between'
  )
  const isRecyclingVisible = singleEntity?.recyclingColumn?.recycling
    ? Object.keys(singleEntity?.recyclingColumn?.recycling as Object).length > 0
    : false

  return (
    <ContentBodySection title="" key={customKey}>
      <div className="mx-4 max-lg:mx-1">
        <Accordion
          sx={{
            ':first-of-type': { borderRadius: '1rem' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="CO2Values-main-content"
            id="CO2Values-main-header"
          >
            <div className={accordionCSS}>
              {entityAccordion && getColumIconWithTitle(entityAccordion)}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={columnCSS}>
              {singleEntity?.primaryColumn &&
                getAccordionColumData(
                  t(`${singleEntity?.primaryColumn.title.name}`),
                  singleEntity?.primaryColumn.title.unit,
                  dataConverter(singleEntity?.primaryColumn.primary),
                  columnLength && columnLength <= 2 ? 'basis-2/5' : ''
                )}
              {singleEntity?.recyclingColumn &&
                isRecyclingVisible &&
                getAccordionColumData(
                  t(`${singleEntity?.recyclingColumn?.title.name}`),
                  singleEntity?.recyclingColumn?.title.unit,
                  dataConverter(singleEntity?.recyclingColumn?.recycling)
                )}
              {singleEntity?.infoColumn &&
                getAccordionColumData(
                  t(`${singleEntity?.infoColumn.title}`),
                  undefined,
                  dataConverter(singleEntity?.infoColumn?.information || [])
                )}
                {singleEntity?.totalColumn &&
                getAccordionColumData(
                  '',
                  undefined,
                  dataConverter(singleEntity?.totalColumn?.data || [])
                )}
            </div>
            {emission && prepareSingleEntityModal()}
          </AccordionDetails>
        </Accordion>
      </div>
    </ContentBodySection>
  )
}

export default SingleEntityCO2Values
