import { MouseEvent } from 'react'

export interface iLooseObject {
  [key: string]: any | any[]
}

export interface Data {
  id?: string
  name: string
  context?: string
  category?: string
  type: string
}

export enum eSearchParametersStatus {
  active = 'active',
  inactive = 'inactive',
}
export interface iSearchParameters_ConsumerProducts {
  id?: string
  category?: string
  type: string
  countries: string
  period: string
  isValid: boolean
  includeBenchmark?: boolean
}

// TODO: fix interface
export interface iSearchParameters_MaterialComposites {
  id?: string
  category?: string
  type: string
  contries: string
  period: string
  isValid: boolean
}

export interface iSearchParameters_Data
  extends iSearchParameters_ConsumerProducts,
    iSearchParameters_MaterialComposites,
    iLooseObject {
  isActive: boolean
  status: boolean
  deliveryShape?: string
  validityStart: string | null
  validityEnd: string | null
  distance?: string | null
  includePyrolysis?: string | null
  includeUptake?: string | null
  materialMeasure?: string | null
  [key: string]: any
}

export interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

export interface iSearchParametersHeadCells {
  disablePadding: boolean
  id:
    | keyof iSearchParameters_ConsumerProducts
    | keyof iSearchParameters_MaterialComposites
  label: string
  numeric: boolean
}
export type Order = 'asc' | 'desc'

export interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  rowCount: number
}
export interface iSearchParametersEnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property:
      | keyof iSearchParameters_ConsumerProducts
      | keyof iSearchParameters_MaterialComposites
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

export interface iSaveSearchParameters {
  category: string
  type: string
  countries?: string | null
  isValid?: boolean
  validityStart?: string
  validityEnd?: string
  includeBenchmark?: boolean
  distance?: number
  includeUptake?: boolean
  deliveryShape?: string
  materialMeasure?: number
  id?: number
  includePyrolysis?: boolean
  isActive?: boolean
  uuid?: string
}
export interface iRoleMissingResponseError {
  status: number
  requiredPermission: string
}
export interface iSearchParamsTableColumns extends iLooseObject {
  id?: string | null
  category?: string | null
  type?: string | null
  countries?: string | null
  period?: string | null
  isValid?: boolean | null
  includeUptake?: string | null
  deliveryShape?: string | null
  includePyrolysis?: string | null
  isActive?: boolean | null
  distance?: string | null
  materialMeasure?: string | null
  [key: string]: any
}
