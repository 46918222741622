import { HistoryContext } from 'config/context/UrlContext'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { useContext } from 'react'
import { useNavigate as useRouterNavigate } from 'react-router-dom'
import { usePopstate } from './usePopstate'
import { useCustomNavigate } from './useCustomNavigate'
import { useBeforeUnload } from './useBeforeUnload'

export interface UrlPatcher {
  pathname?: string
  appendPathname?: boolean
  searchParams?: URLSearchParams
  appendSearchParams?: boolean
  hash?: string
  appendHashValue?: boolean
  shouldNavigate?: boolean
}

/**
 * A hook for using URL capabilities.
 *
 * @returns An object with functions and attribute values, related to
 * the browser's URL.
 */
export default function useUrl() {
  const { history } = useContext(HistoryContext)
  const navigate = useRouterNavigate()

  /**
   * Returns the current URL object.
   *
   * @returns An URL object of the current URL
   */
  const getCurrentUrl = (): URL => {
    if (history.length > 0) return history[0]
    else return new URL(window.location.href)
  }

  /**
   *
   * @param pos The position in history, where 0 is current and -1 is previous
   * @returns The URL object of the item in history
   */
  const getHistoryUrl = (pos: number): URL | undefined => {
    let _pos = pos * -1 // invert from negative to positive
    if (history.length < 2) return undefined // no history
    else if (_pos < 0) return undefined // no sub zero access allowed
    else if (_pos > history.length - 1) return undefined // out of range
    else return history[_pos]
  }

  /**
   * Function used for updating the URL object and navigating there.
   *
   * @param patcher A patcher objects, containing the following attributes
   * -> pathname: The URL path /the/path/of/the/url
   * -> appendPathname: Whether the value should be appended to path
   * -> searchParams: URL Search parameters object with key/value of URL search params
   * -> appendSearchParams: Whether the params should be appended
   * -> hash: The URL hash value
   * -> appendHashValue: Whether the value parsed should be appended
   * -> shouldNavigate: Whether the React router navigation should be triggered. No URL
   * object will be returned in this case.
   *
   * @returns If navigation is not active, a URL object will be returned.
   */
  const patchUrl = (patcher: UrlPatcher): URL | void => {
    /** Get variables */
    let url = getCurrentUrl()

    let {
      pathname,
      appendPathname,
      searchParams,
      appendSearchParams,
      hash,
      appendHashValue,
      shouldNavigate,
    } = patcher

    /** Initialize all booleans */
    appendPathname = appendPathname === undefined ? false : appendPathname
    appendSearchParams =
      appendSearchParams === undefined ? false : appendSearchParams
    appendHashValue = appendHashValue === undefined ? false : appendHashValue
    shouldNavigate = shouldNavigate === undefined ? false : shouldNavigate

    /** pathname */
    if (pathname && appendPathname) url.pathname = `${url.pathname}${pathname}`
    if (pathname && !appendPathname) url.pathname = pathname

    /** search params */
    if (searchParams && appendSearchParams) {
      let currentParams = url.searchParams as any
      let newParams = [...currentParams, searchParams]
      Object.defineProperty(url, 'searchParams', newParams)
    }

    if (searchParams && !appendSearchParams)
      Object.defineProperty(url, 'searchParams', searchParams as any)

    /** hash */
    if (hash && appendHashValue) url.hash = `${url.hash}${hash}`
    if (hash) url.hash = hash

    /** Navigation if desired else return URL object */
    if (shouldNavigate) {
      /** Setup the relative URL for navigation */
      let navigationString = url.toString()
      let navigationStringWithoutHost = navigationString.replace(
        `${url.protocol}//${url.host}`,
        ``
      )

      navigate(navigationStringWithoutHost, {
        replace: false,
      })
    } else {
      return url
    }
  }

  /**
   * Helper to convert object to URLSearchParams
   * @param o URLSearchParams Object to be converted
   * @returns iLooseObject of the URLParamsObject
   */
  const objectToURLSearchParams = (o: iLooseObject): URLSearchParams => {
    let params: URLSearchParams = new URLSearchParams()

    for (let [key, value] of Object.entries(o)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          params.append(key, v)
        })
      } else {
        params.append(key, value)
      }
    }

    return params
  }

  /**
   * Helper to convert URL Search Params to loose Object
   * @param sp URLSearchParams object to be converted to loose object
   * @returns Loose object with params as single value or array
   */
  const urlSearchParamsToObject = (sp: URLSearchParams): iLooseObject => {
    let params: iLooseObject = {}

    let entries = sp.entries()

    while (true) {
      const entry = entries.next()
      if (entry.done) break
      const [key, value] = entry.value

      if (Object.keys(params).includes(key) && !Array.isArray(params[key])) {
        let prevValue = params[key]
        params[key] = [prevValue, value]
      }
      if (Object.keys(params).includes(key) && Array.isArray(params[key])) {
        if (!params[key].includes(value)) params[key].push(value) // only distinct
      } else {
        params[key] = value
      }
    }

    return params
  }

  /**
   * Returns the navigatable url path from a window.location
   * @param loc The window location object
   * @returns A relative url path
   */
  const windowLocationToRelativePathString = (
    loc: typeof window.location
  ): string => {
    return loc.toString().replace(`${loc.protocol}//${loc.host}`, ``)
  }

  /**
   * Returns a navigatable string from a URL object
   * @param url a typical URL object
   * @returns a relative navigatable string
   */
  const urlToRelativePathString = (url: URL): string => {
    return url.toString().replace(`${url.protocol}//${url.host}`, ``)
  }

  /**
   * Helper for returning a Location from a URL object
   * @param url a URL object
   * @returns a Location Object
   */
  const urlToLocation = (url: URL): Location => {
    return { ...url } as unknown as Location
  }

  return {
    history: history,
    useCustomNavigate: useCustomNavigate,
    usePopstate: usePopstate,
    useBeforeUnload: useBeforeUnload,
    getCurrentUrl: getCurrentUrl,
    getHistoryUrl: getHistoryUrl,
    patchUrl: patchUrl,
    objectToUrlSearchParams: objectToURLSearchParams,
    urlSearchParamsToObject: urlSearchParamsToObject,
    windowLocationToRelativePathString: windowLocationToRelativePathString,
    urlToRelativePathString: urlToRelativePathString,
    urlToLocation: urlToLocation,
  }
}
