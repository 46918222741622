import { useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { ReactComponent as NoRulesSetYet } from 'assets/images/no_rules_set_yet.svg'

import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  iLooseObject,
  iSearchParameters_Data,
  iSearchParamsTableColumns,
} from 'pages/DataHub/filters/types'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import {
  sagaDeleteSearchParametersDataAction,
  sagaUpdateSearchParameterStatusDataAction,
} from 'saga/actions/dataHub'

import ModalWrapper from '../../../../components/custom/ModalWrapper'
import SelectWrapper from '../../../../components/custom/SelectWrapper'
import { iUpdateStatusSearchParameter } from 'saga/sagas/dataHub/types'
import dayjs from 'dayjs'
import { selectedSearchCategoryState } from 'store/slices/searchParametersSlice'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import {
  addSpaceInCommaSeparatedString,
  capitaliseEveryWordInString,
} from 'config/lib'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eColor } from 'components/types'
import { susIcons } from 'config/services/requests'

interface iProps {
  children?: JSX.Element | JSX.Element[]
  title: string
  rows?: iSearchParameters_Data[]
}

const BasicTableWrapper = ({ rows = [] }: iProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tableRows, setTableRows] = useState<iSearchParamsTableColumns[]>([{}])
  const searchParametersCategoryData: string = useSelector(
    selectedSearchCategoryState
  )
  const settingRules = useSelector(dataHubReducerState).hub.settingRules

  useEffect(() => {
    const newTableRows: iSearchParamsTableColumns[] = (rows || [{}]).map(
      (item: iSearchParameters_Data, index: number) => {
        const newRow: iLooseObject = {}
        settingRules.forEach((settingRule: string, index: number) => {
          // only apply rule to period when setting:
          if (settingRule === 'period') {
            const startDate = dayjs(item['validityStart']).format('MM/YY')
            const endDate = dayjs(item['validityEnd']).format('MM/YY')
            const newValue: string =
              item && item['validityStart'] && item['validityEnd']
                ? `${startDate}-${endDate}`
                : t('general.na')

            newRow[settingRule] = {
              name: settingRule,
              value: newValue,
            }
          } else {
            newRow[settingRule] = {
              name: settingRule,
              value: item[settingRule] ? item[settingRule] : '',
            }
          }

          //set the id only once in the loops
          if (index === 0) {
            newRow['id'] = { name: 'id', value: item.id }
          }
        })
        return newRow
      }
    )
    setTableRows(newTableRows)
  }, [rows, searchParametersCategoryData, settingRules, t])
  const [searchParamId, setSearchParamId] = useState('0')

  const generateLabel = useCallback(
    (key: string) => t(`dataHub.searchParameters.${key}`),
    [t]
  )

  const generateTableHead = useCallback(
    (row: iSearchParamsTableColumns) => {
      return (
        <TableHead>
          <TableRow>
            {Object.keys(row || [])
              .filter((key) => key !== 'id')
              .map((key) => {
                return (
                  <TableCell align="center" key={`header-key-${key}`}>
                    {generateLabel(key)}
                  </TableCell>
                )
              })}
            <TableCell align="center">{generateLabel('action')}</TableCell>
          </TableRow>
        </TableHead>
      )
    },
    [generateLabel]
  )

  const generateTableCell = useCallback(
    (cellContent: string | JSX.Element, index: number, className?: string) => (
      <TableCell
        align="center"
        key={`table-cell-key-${index}`}
        className={className}>
        {cellContent ? cellContent : t('general.na')}
      </TableCell>
    ),
    [t]
  )

  const onStatusChange = useCallback(
    (statusData: iUpdateStatusSearchParameter) => {
      dispatch(sagaUpdateSearchParameterStatusDataAction(statusData))
    },
    [dispatch]
  )

  const generateRow = useCallback(
    (row: iSearchParamsTableColumns, rowIndex: number) => {
      let result: JSX.Element[] = []
      Object.keys(row)
        .filter((key) => key !== 'id') //ignore the id
        .forEach((key: string, index: number) => {
          const tempItem: iLooseObject | any = row && row[key]
          const isActive: boolean = tempItem.value
          switch (key) {
            case 'countries':
              result.push(
                generateTableCell(
                  tempItem && tempItem.value
                    ? addSpaceInCommaSeparatedString(tempItem.value)
                    : t('general.na'),
                  index
                )
              )
              break
            case 'isValid':
              result.push(
                generateTableCell(
                  isActive ? t('general.yes') : t('general.no'),
                  index
                )
              )
              break
            case 'includeUptake':
              const includeUptake: boolean = tempItem.value

              result.push(
                generateTableCell(
                  includeUptake ? t('general.yes') : t('general.no'),
                  index
                )
              )
              break
            case 'includePyrolysis':
              const includePyrolysis: boolean = tempItem.value

              result.push(
                generateTableCell(
                  includePyrolysis ? t('general.yes') : t('general.no'),
                  index
                )
              )
              break
            case 'period':
              result.push(
                generateTableCell(
                  tempItem ? tempItem.value : t('general.na'),
                  index
                )
              )
              break
            case 'isActive':
              result.push(
                generateTableCell(
                  <SelectWrapper
                    defaultValue={tempItem?.value || 'false'}
                    onChange={(newValue: string) =>
                      onStatusChange({
                        id: row['id'] || '0',
                        status: newValue,
                      })
                    }>
                    <MenuItem key={index + 'selectedTrue'} value={'true'}>
                      {t('dataHub.searchParameters.active')}
                    </MenuItem>
                    <MenuItem key={index + 'selectedFalse'} value={'false'}>
                      {t('dataHub.searchParameters.inactive')}
                    </MenuItem>
                  </SelectWrapper>,
                  index,
                  'w-32'
                )
              )
              break
            default:
              result.push(
                generateTableCell(
                  tempItem && tempItem.value
                    ? typeof tempItem.value === 'number'
                      ? tempItem.value
                      : capitaliseEveryWordInString(tempItem.value.toString())
                    : t('general.na'),
                  index
                )
              )
              break
          }
        })

      return result
    },
    [generateTableCell, onStatusChange, t]
  )
  const onYesClick = useCallback(() => {
    setIsModalOpen(false)
    dispatch(sagaDeleteSearchParametersDataAction(searchParamId.toString()))
  }, [dispatch, setIsModalOpen, searchParamId])

  return tableRows.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {generateTableHead(tableRows[0])}
        <TableBody>
          {tableRows.map((row: iSearchParamsTableColumns, index: number) => (
            <TableRow
              key={`${row.category}${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {generateRow(row, index)}
              <TableCell align="center">
                <span
                  onClick={() => {
                    const itemToDelete: any = row && row.id

                    setIsModalOpen(true)
                    setSearchParamId(itemToDelete.value || '0')
                  }}
                  className="flex cursor-pointer text-center items-center justify-center dark:fill-white">
                    <susIcons.Trash className=" h-6 w-6 mr-2 dark:fill-white"    title={t('general.delete')}/>
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* MODAL START */}
      <ModalWrapper
        onClose={() => {
          setIsModalOpen(false)
        }}
        open={isModalOpen}
        footerSection={
          <div className="space-x-2 pt-2">
            <ButtonWrapper
              color={eColor.secondary}
              onClick={() => {
                onYesClick()
              }}
              aria-label={t('general.yes')}>
              {t('general.yes')}
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                setIsModalOpen(false)
              }}
              aria-label={t('general.no')}>
              {t('general.no')}
            </ButtonWrapper>
          </div>
        }>
        <>
          <h1 className="text-xl pb-4">
            {t('dataHub.searchParameters.deleteModal.title')}
          </h1>
          <p className=" pb-2">
            {t('dataHub.searchParameters.deleteModal.message')}
          </p>
        </>
      </ModalWrapper>
      {/* MODAL END */}
    </TableContainer>
  ) : (
    <div className="flex place-content-center p-10">
      <div className="flex flex-col items-center">
        <Typography
          variant={`h5`}
          color={`dark`}
          sx={{
            marginBottom: `20px`,
          }}
          role="heading"
          aria-label={t('dataHub.searchParameters.noRulesSetYet')}>
          {t('dataHub.searchParameters.noRulesSetYet')}
        </Typography>
        <div>
          <NoRulesSetYet />
        </div>
      </div>
    </div>
  )
}

export default BasicTableWrapper
