import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes } from 'saga/actions/dataHub'
import { PayloadAction } from '@reduxjs/toolkit'
import { iSingleEntity } from 'store/types'
import { setSingleEntity } from 'store/slices/singleEntity'
import { iRoleMissingResponseError } from 'pages/DataHub/filters/types'
import { setSearchCategory } from 'store/slices/searchParametersSlice'
import { iSingleEntityParams } from 'config/services/requests/dataHub/types'

export function* getSingleEntitySaga(
  action: PayloadAction<iSingleEntityParams>
): Generator<iSingleEntity | iRoleMissingResponseError | any> {
  const params = action.payload
  try {
    const contextNameValues = params.contextName.split('.')
    const databaseCategory: string = contextNameValues[1].toString()
    const typeCategory: string =
      contextNameValues.length > 2 ? contextNameValues[2].toString() : ''
    //set category for search params
    yield put(setSearchCategory(databaseCategory))
    //get search params data based on category
    const searchParameters: any = yield call(
      apiRequests.getSearchParametersData,
      1,
      databaseCategory
    )

    let singleEntityParams: iSingleEntityParams
    //filter based on entity contextName type

    const matchIndex: number = searchParameters.findIndex(
      (item: any) =>
        (item.type === typeCategory || !item.type) && item.isActive === true
    )

    if (matchIndex !== -1) {
      const singleEntityRequestParams = searchParameters[matchIndex]

      //generate new request params with
      singleEntityParams = {
        contextName: params.contextName,
        uuid: params.uuid,
        ...singleEntityRequestParams,
      }
    } else {
      //generate new request params with
      singleEntityParams = {
        contextName: params.contextName,
        uuid: params.uuid,
      }
    }
    // return
    //get single entity Results
    let results: iSingleEntity | iRoleMissingResponseError | any = yield call(
      apiRequests.getSingleEntityRequest,
      singleEntityParams
    )
    if (results !== undefined) {
      // save search results to global state
      yield put(setSingleEntity(results))
    }
  } catch (e) {
        //catchind errors
  }
}

export default function* getSingleEntityDataHook() {
  yield takeLeading(
    dataHubTypes.SAGA_GET_SINGLE_ENTITY_DATA,
    getSingleEntitySaga
  )
}
