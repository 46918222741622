import { FC, useCallback, useEffect, useState } from 'react'
import { Checkbox, Divider, FormControlLabel, Typography } from '@mui/material'
import { ContentBody } from 'components/pages/components/ContentBody'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import SingleEntityBaseInformation from 'pages/SingleEntity/components/SingleEntityBaseInformation'
import SingleEntityCO2Values from 'pages/SingleEntity/components/SingleEntityCO2Values'
import { useDispatch, useSelector } from 'react-redux'
import {
  sagaGetDataHubCategoriesAction,
  sagaGetSingleEntityDataAction,
} from 'saga/actions/dataHub'
import { useParams, useSearchParams } from 'react-router-dom'
import { singleEntityState } from 'store/slices/singleEntity'
import {
  iCo2AccordionEntity,
  iCo2Emission,
  iCo2SingleEntity,
} from '../../store/types/singleEntity'
import { createAccordionEntity, createSingleEntityObj } from 'config/lib'
import { iSingleEntityParams } from 'config/services/requests/dataHub/types'
import PaperWrapper from 'components/custom/PaperWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import ModifySettingsSVG from 'assets/icons/modify-search-settings.svg'
import { clearHubCategories } from 'store/slices/dataHubCategories'
import { iOrganizationData, userState } from 'store/types'
import { userReducerState } from 'store/slices/user'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import ModalWrapper from 'components/custom/ModalWrapper'
import { eButtonTypes, eButtonSize } from 'components/types'
import { DataAsServiceAggrement } from 'pages/Legal/DataAsServiceAggrement'
import {
  sagaGetOrganizationProfileDataAction,
  sagaGetOrganizationTeamMembersAction,
  sagaUpdateMemberAction,
} from 'saga/actions/user'
import { isTermsAccepted } from 'config/services/requests/lib'
import { ITermsAccepted } from 'config/services/requests'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iOrganizationManageTeam } from 'pages/user/types'

const SingleEntityContent: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const [entityTitle, setEntityTitle] = useState('')
  const [entityRoute, setEntityRoute] = useState('')

  const entityData: any =
    useSelector(singleEntityState).singleEntity?.entity?.instance

  // first load: (clear category items)
  useEffect(() => {
    return () => {
      // clear categories global state
      dispatch(clearHubCategories())
    }
  }, [dispatch])

  /* Reload Child menu items */
  useEffect(() => {
    dispatch(sagaGetDataHubCategoriesAction())
  }, [dispatch])

  // on FIRST LOAD (and ID change)
  useEffect(() => {
    const contextName = searchParams.get('contextName')
    const currentEntityParams: iSingleEntityParams = {
      uuid: id || '',
      contextName: contextName || '',
    }

    dispatch(sagaGetSingleEntityDataAction(currentEntityParams))
    //disable for not loading when searchParams get updated (because tvhey will in the popup)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  //setting the entity Data
  useEffect(() => {
    let entityTitle: string = entityData?.information?.identification?.title
    const entityTitleLength = entityTitle?.length - 1

    if (entityTitle?.endsWith('%'))
      entityTitle = entityTitle.slice(0, entityTitleLength)

    setEntityTitle(entityData?.information?.identification?.title)
    setEntityRoute(entityTitle)
  }, [entityData])

  const getcO2EmissionData = useCallback(() => {
    return entityData.co2Emission.map(
      (emission: iCo2Emission, index: number) => {
        const tempContextName = searchParams.get('contextName')?.toString()

        const co2Data: iCo2SingleEntity | undefined = createSingleEntityObj(
          entityData,
          tempContextName || '',
          index
        )

        const co2Accordion: iCo2AccordionEntity | undefined =
          createAccordionEntity(emission, tempContextName || '')
          
        return (
          <SingleEntityCO2Values
            key={`emission${index}`}
            emission={emission}
            singleEntity={co2Data}
            entityAccordion={co2Accordion}
          />
        )
      }
    )
  }, [entityData, searchParams])

  return (
    <ContentBody title={entityTitle || t('singleEntity.title')}>
      {/* TODO: implement make favorite */}

      <SingleEntityBaseInformation entity={entityData} />
      <PaperWrapper className="flex mt-4 pb-1 flex-col">
        <div className="flex justify-between pt-5 pr-5">
          <Typography
            variant="h4"
            className="h-full px-4"
            fontSize="1.5rem"
            fontWeight="500"
          >
            {t('singleEntity.CO2Values.title')}
          </Typography>
          <div className="flex items-center">
            <CustomSvgStyled src={ModifySettingsSVG} />
            <LinkWrapper
              to={`${
                RoutePaths.dataHubSearchParams
              }?uuid=${id}&contextName=${searchParams
                .get('contextName')
                ?.toString()}&entityTitle=${entityRoute}`}
            >
              {t('singleEntity.CO2Values.modifySearchParameters')}
            </LinkWrapper>
          </div>
        </div>
        <div className="w-full">
          {entityData && entityData.co2Emission && getcO2EmissionData()}
        </div>
      </PaperWrapper>
    </ContentBody>
  )
}

const SingleEntity = ()=> {

  const userInfo: userState = useSelector(userReducerState);
  const organizationData: iOrganizationData | any = useSelector(
    organizationProfileState
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isAccepted, setIsAccepted] = useState(false)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Need org Id while updating user
    dispatch(sagaGetOrganizationProfileDataAction())
  }, [dispatch])

  useEffect(() => {
    if (userInfo?.organization?.id)
      dispatch(
        sagaGetOrganizationTeamMembersAction(parseInt(userInfo.organization.id))
      )
      else setIsLoading(false)
  }, [userInfo])

  useEffect(() => {
    organizationData.team.forEach((orgData: iOrganizationManageTeam) => {
      if (userInfo.id === orgData.id) {
        setHasSubscription(!!orgData?.subscriptionId)
        setIsLoading(false)
      }
    })
  }, [organizationData, userInfo])

  const onAccept = () => {
    dispatch(
      sagaUpdateMemberAction({
        clearCache: true,
        userId: userInfo.id,
        attributes: {
          terms_and_conditions: ITermsAccepted.ON,
        },
      })
    )
  }

  return !isTermsAccepted() && !!hasSubscription? (
    <ModalWrapper
      open={true}
      preventClose
      className={'lg:min-w-[800px]'}
      footerSection={
        <div className=" space-x-5 pt-2 pb-4  ">
          <Divider className="mt-2 mb-6 bg-secondary" flexItem />
          <div className="flex justify-between">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setIsAccepted(!isAccepted)}
                  style={{ marginRight: 8 }}
                  checked={!!isAccepted}
                />
              }
              label={t('singleEntity.daasModal.acceptDaaS')}
            />

            <ButtonWrapper
              onClick={onAccept}
              type={eButtonTypes.submit}
              size={eButtonSize.medium}
              aria-label={t('singleEntity.daasModal.acceptButton')}
              disabled={!isAccepted}
            >
              {t('singleEntity.daasModal.acceptButton')}
            </ButtonWrapper>
          </div>
        </div>
      }
    >
      <div className="overflow-y-scroll max-h-[75vh]">
        <DataAsServiceAggrement />
      </div>
    </ModalWrapper>
  ) : (
    !!isLoading ?<></> : <SingleEntityContent />
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: [RoutePaths.singleEntity],
    title: 'singleEntity.title',
    element: <SingleEntity />,
    roles: componentPermissions[componentNames.pageSingleEntity],
    requiresAuthentication: true,
  },
  name: 'SingleEntity',
}
export default exportObj
