import ExcelJS from 'exceljs';
import { tContextCollection, tFileContextCollection, iContextItem, iContextMetal, iContextPolymer } from 'store/types/massFileUpload';
import { buildSearchTerms, getContextNameFromWorksheet, handleCellValue, isRecycled, stringToLowerCase, stripSpaces } from './helpers';

/**
 * 
 * @param file The uploaded file using the dropzone
 * @returns A promise resolving in an ExcelJS Workbook Object from the file
 */
export const createWorkbookFromFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    let workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
    
    /** Create a buffer from file */
    file.arrayBuffer()
      .then((buffer) => {

        /** Load the buffer into the workbook object */
        workbook.xlsx.load(buffer)
          .then(() => {
            
            /** Resolve the workbook object from file */
            resolve(workbook);

          /** Catching loading Excel file */
          }).catch((error: any) =>  {
            let errorMsg = `Could not load Excel File: ${error.toString()}`;
            console.error(errorMsg);
            reject(errorMsg);
          });
      
      /** Catching Buffer Creation */
      }).catch((error: any) => {
        let errorMsg = `Could not create buffer from file: ${error}`;
        console.error(errorMsg);
      });
  });
}

/**
 * Calculate the total number of rows, included in all files
 * @param collection The collection of files and their rows as objects
 * @returns A total of data records inside the collection
 */
export const getTotalInputFileContextCollectionDataRecords = (collection: tFileContextCollection): number => {
  let count = 0;
  Object.values(collection).forEach(contextCollection => {
    Object.values(contextCollection).forEach(context => {
      count += context.length;
    });
  });
  return count;
}

/**
 * Calculate the total number of rows in one file
 * @param contextCollection The rows contained in worksheets of any one file
 * @returns A total of data records inside the collection (minus header row)
 */
export const getTotalContextCollectionRecords = (contextCollection: tContextCollection): number => {
  let count = 0;
  Object.values(contextCollection).forEach(context => {
    count += context.length;
  });
  return count;
}

/**
 * Returns a FileContextCollection object, which contains the rows of an excel file
 * as objects, including the scope and also the file name of the excel file that
 * was uploaded by the user.
 * 
 * @param files The uploaded files
 * @returns The contents of the files in object form
 */
export const excelFilesToFileContextCollection = async (files: File[]) => {
  return new Promise((resolve, reject) => {

    let FileContextCollection: tFileContextCollection = {};
    
    /** Loop all files to be entries inside the file collection */
    files.forEach(file => {

      let fileName = file.name;

      createWorkbookFromFile(file)
        .then(_wb => {
          
          /** Obtain the context collections (worksheet data) from the workbook */
          let wb = (_wb as any) as ExcelJS.Workbook;
          let contextCollection: tContextCollection = {};

          wb.worksheets.forEach(worksheet => {
            let contextName = getContextNameFromWorksheet(worksheet)
            let contextCollectionData = worksheetToContextCollectionData(worksheet);
            
            contextCollection[contextName] = contextCollectionData;
          });

          /** Write back the contextCollections into the file collection */
          FileContextCollection[fileName] = contextCollection;
          if (files.length === Object.keys(FileContextCollection).length)
            resolve(FileContextCollection);
        });
    });
  });
}

/**
 * 
 * @param worksheet The worksheet object from the Excel workbook
 * @returns 
 */
export const worksheetToContextCollectionData = (
  worksheet: ExcelJS.Worksheet
): unknown[] => {
  const sheetName = worksheet.name.toLowerCase();

  let items: unknown[] = [];

  worksheet.eachRow((row, rowNumber) => {
    let contextItem = rowToContextItem(rowNumber, row, sheetName);
    if (contextItem === undefined) return;
    items.push(contextItem);
  })

  return items;
}

const rowToContextItem = (
  rowNumber: number, 
  row: ExcelJS.Row, 
  type: string
): iContextItem|iContextMetal|iContextPolymer|undefined => {

  if (rowNumber <= 1) return undefined;

  /** Building the objects from the row data */
  switch (true) {
    case type === 'metals':
        return {
            rowNumber: rowNumber,
            terms: buildSearchTerms(
              handleCellValue(
                row.getCell(1).value, 
              )
            ),
            isRecycled: isRecycled(row.getCell(2).value) || false,
            country: row.getCell(3).value || 'OECD TOTAL',

            deliveryShape: handleCellValue(
              row.getCell(4).value,
              'Raw',
              [stripSpaces, stringToLowerCase]
            ),

            referenceType: handleCellValue(
              row.getCell(5).value,
              'Common',
              [stringToLowerCase]
            ),

        } as iContextMetal
    case type === 'polymers':
        return {
            rowNumber: rowNumber,
            terms: buildSearchTerms(
              handleCellValue(
                row.getCell(1).value
              ), 
            ),

            isRecycled: isRecycled(row.getCell(2).value) || false,
            country: handleCellValue(row.getCell(3).value, 'OECD TOTAL'),

            referenceType: handleCellValue(
              row.getCell(4).value && row.getCell(4).value,
              'Common',
              [stripSpaces, stringToLowerCase]
            )
        } as iContextPolymer
    case type === 'composites': 
        return {
            rowNumber: rowNumber,
            terms: buildSearchTerms(
              handleCellValue(
                row.getCell(1).value
              )
            ),
            isRecycled: isRecycled(row.getCell(2).value) || false,
            country: handleCellValue( row.getCell(3).value, 'OECD TOTAL'),
        } as iContextItem
    default: return undefined;
    }
}