

import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList } from './helpers'
import MaterialTypesOverview from 'assets/images/KnowledgeHub/MaterialsDocument/MaterialTypesOverview.png'
import Materials_Fig2 from 'assets/images/KnowledgeHub/MaterialsDocument/Materials_Fig2.png'
import MaterialsFlowOfTheReferenceModelling from 'assets/images/KnowledgeHub/MaterialsDocument/MaterialsFlowOfTheReferenceModelling.png'
import PloymersFlowOfTheReferenceModelling from 'assets/images/KnowledgeHub/MaterialsDocument/PloymersFlowOfTheReferenceModelling.png'


export const MaterialsDocumentData: IAccordionData[] = [
  {
    title: 'MATERIALS',
    content: `The database on materials contains CO2e values for different types of materials (metals, sintered metals, plastics, composite materials, organic materials, construction materials). For metallic materials, the values for typical semi-finished products can also be found. The determination of material CO2 data for metals is based on calculations and/or direct data sources. A distinction is made between base materials (elements) and raw materials (element groups), alloys and semi-finished products. The values for alloys are calculated on the basis of chemical composition (percentages by weight) and manufacturing processes for processing (e.g., ore processing, smelting, alloying) is taken into account.
    <br />
    ${getImage(MaterialTypesOverview, 'Material Types Overview')}
    <br />
    `,
    isOpen: true,

    children: [
      {
        title: 'CALCULATION VARIANT SCENARIOS',
        content: `sustamize's calculation methodology acknowledges the extensive and broad data coverage in specific fields such as for materials. Based on the results of data research and data clustering, sustamize provides its users with a range of calculations depending on the different processes used, and gives several options of CO2 values:
        <br />  <br />    
        ${makeBulttePointsList(" Common|Mean|Median|Minimal|Maximal")}   
        <br />  
        Following these criteria, the material database user is able to assess different scenarios. Currently, our software partners only use the common value.
        <br />  <br />    
        <u><b>Common</u></b>
        <br />    
        The common value was introduced as a way of reducing difficulty in choosing a value within the array of possible values. This reference data represents, as the name suggests it, the most common process used for the production of a given material in that industry, worldwide. A differentiation according to local most commonly used process is not yet provided. Our LCA experts select the relevant studies and choose those with common processes as main sources for the calculation. In addition to technological factors, important references in the choice of values are linked to the quality of a given study and its age. For example, a recent study may reflect a cutting-edge type of production that has not yet entered general production and therefore cannot be used for a common value. An older study, perhaps of lesser overall quality, could nevertheless be deemed more accurate in such a case, as it is most likely widely implemented. Otherwise, if the two studies were of equal quality and technological importance, the more recent one would be used as the reference for the common value. On the other hand, if they were produced at roughly the same time, the higher-quality study will be used. The base calculation of these CO2e references refers to the country of production. However, sustamize also uses this as grounds for the simulation of country impact (energy mix of the specific country).
        <br />    
        <b>Recommendation:</b> The “common” data of the reference dataset should be the selection of choice as this data reflects the status quo in most of the processes and applications. This means that this data should cover the needs of typical product footprint analyses.
        <br />  <br />    
        <u><b>MEAN</u></b>
        <br />    
        Another type of data is the selection of "mean" processes. In this case, not the most common industrial processes, such as refining, alloying and metal or polymer processing, are used, but the median of all processes in each category of the database is used for the calculation.
        <br />    
        <b>Recommendation:</b> The use of "mean" data is recommended only as a reference value, not for specific analyses.
        <br />  <br />    
        <u><b>MEDIAN</u></b>
        <br />    
        A similar type of data to the “mean” is the “median” process selection. In the same way as with the mean not the most common industrial used processes like for refinement, alloying and metal or polymer processing are used but the median process of each category in the database is used for the calculation.
        <br />   
        <b>Recommendation:</b> The use of “median” data can be used like the mean value and is usually preferable as it is not biased towards outliers that might influence the result in one way or another.
        <br />  <br />    
        <u><b>MIN</u></b>
        <br />    
        For use in optimization scenarios, two other data ranges are offered. The first one is the “minimal” or “min” value. This value is based on using the most efficient processes found in our current research. It represents the lowest value of a CO2e Footprint of a material and can be achieved by optimizing processes alone.
        <br />   
        <b>Recommendation:</b> For optimization scenarios, this value can be used to compare a current footprint with one that includes a high degree of optimization. The minimal value can also be useful for planning large-scale changes in supply chains, i.e. building up a new material supply with state-of-the-art technology.
        <br />  <br />    
        <u><b>MAX</u></b>
        <br />    
        Analogous to the minimal value, the “maximal” or “max” data type signifies the opposite. It represents the scenario of the least efficient processes used according to our current research. It means the highest value a material should reach using inefficient processes. Understandably, in some aspect there is no limit to inefficiencies and how high the value for a material could rise. Nevertheless, “max” is a good representation of a still functional but inefficient production process.
        <br />   
        <b>Recommendation:</b> This data set can be used to simulate an inefficient production in worst case scenario analysis or as a comparison with other scenarios. It can also be useful if a specific location is known to
      `,
        isOpen: true,
      },
      {
        title: 'PRIMARY VS. RECYCLING',
        content: `sustamize offers for composites, metals and polymers not just the different calculation categories but also a recycling value besides the value for 100 % primary materials. However, a recycling value is only provided if case studies published a specific recycling value. As available data on recycling remains scarce, data points are still insufficient. Hence, a recycling quota for some materials can sometimes not be provided and thus a range cannot be currently given either. That is why in some cases, the value displayed in common, min, max, etc. will be the same.
        <br /> <br />
        Any recycling material's value calculation is made under the assumption that 100 % of the material is recycled just as the base calculation for primary material assumes that 100 % of the material is virgin. If a material mix is needed to represent a certain recycling quota, both values need to be used and combined according to their respective percentage. For example, a material made of 40% secondary material, that has a footprint of 1.5 kg of CO2e per kg material, and 60 % of primary material, with a footprint of 2.5 kg CO2e per kg material, would have a final footprint of 2.1 kg CO2e per kg of material. Calculation: 0.4 x 1.5 kg + 0.6 x 2.5 kg = 2.1 kg CO2e per kg of material.
      <br />
      `,
        isOpen: false,
      },
      {
        title: 'METALS',
        content: `<u><b>Necessary Input Data</u></b>
        <br />
        The carbon footprint calculation of raw metals or semi-finished metals is based on a generic definition of the following processes:
        <br />
        ${makeBulttePointsList("Mining process|Ore refining processes|Concentration processes|Smelting processes|Refining processes|Converter Processes|Ingot or slab casting processes|<div>Semi-finishing processes like <ol class='ml-8 list-[lower-alpha]'><li>Milling</li><li>Profile extrusion</li><li>Tube / hollow profile extrusion</li></ol></div>", 'list-decimal')}
        sustamize has selected one reference process based on the available technologies in the market and based on the necessary equipment and its energy consumption and added the result in the final metal database as reference point.
        <br />
        ${getImage(Materials_Fig2)}
        <br />
        <div class="text-primary text-center">Materials_Fig2.png</div>
        <br />
        According to the availability of researched data, the calculation can either be based on:
        <br />
        ${makeBulttePointsList("Reference values, if not manufacturing or production process model is available|The calculation of the value based on a reference production process, which includes the necessary energy flow, involved GHG gases, input from the previous process, process times and scrap rates.")}
        <br />  <br />  
      
        <u><b>Definition of Process Chain Options</u></b>
        <br />  
        The data used for the calculation is based on the industrial metal classification standards’ metal composition. To calculate the specific composition of an alloy, a combination of different inputs such as raw material refinement and element processing, is necessary. The different steps of the data generation can be described as follows:
        <br />  
        ${makeBulttePointsList("Composition-based flow definition of the refinement process to the pure element (e.g., chrome ore to refined chrome)| Transport to the alloying company (standard: same country + 250km)| Creation of the composition to define the flows into the alloying process| Alloying from raw material to the final metal based on the specific process for this metal type| Optional transport to semi-finished material processing| <div>Optional processing into semi-finished material:<ol class='ml-8 list-[circle]'> <li>Raw</li><li>Sheet </li>  <li>Hollow Profile </li> <li>Solid Profile </li> </ol></div>")}   
     
        It is important to note that all calculation for primary (non-recycling) material assumes that 100 % of the metal is made from virgin raw material or ore and thus does not contain any recycling material used in production.
        <br />   <br />  
        <u><b>Calculation Process within the PFE Business Logic</u></b>
        <br />  
        In a first step, sustamize uses CO2e-emission references for the refinement of the metal alloys’ base element. These references are typically derived on the basis of the defined sustamize data research process including source types.
        <br />   <br />  
        sustamize has built a selection of the currently used refinement industrial scale processes from raw material/ ore to the pure element. If no other conditions are set for the calculation of the CO2e-reference value, the typical and most widely used industrial process is used as a calculation basis. This value can either be derived from one source or (standard case) the value is recalculated according to the industrial refinement process’ modelling, if the quality of sources is deemed not sufficient for a reliable reference. CO2e data for the elements are set to the equivalent of one kg element weight. The sum of the element’s CO2e value is combined with all other base element reference data according to the selected metal’s alloy chemical composition in weight % of the respective element.
        <br />  
        ${getImage(MaterialsFlowOfTheReferenceModelling, "Figure 3: Materials - Flow of the Reference Modelling")}
        <br />  
        In a next step, the elements’ transport to the place of the alloying site is taken into account based on industrial average distance for a non-specific location as prescribed by the framework for reference data creation. This approach is sufficient and does not lead to quality loss, since transport emissions are far below 1% of total emissions. After creating the element base, the process follows the next step of data calculation for the references. In this step, the alloying process from element to the metal alloy is calculated to add the CO2e emissions on top of the already calculated values for base elements. The process modelling is based on the sustamize approach which is using approved reference processes which are backed by sources if available and the sustamize bottom-up calculation method otherwise. The alloying process depends on metal and alloys and include typical losses. If the current data set should provide a result for primary metals, the calculation is based on a 100 % raw material input without any usage of scrap or recycled material.
        <br />   <br />  
        As in step one, transport from alloying to the next step (semi-finish material production) is considered based on industrial benchmark averages (250 km). The final step of the CO2e calculation covers the work from base raw ingot, cast block etc. to semi-finished materials like sheets, solid and hollow profiles. All processes are based on proven industrial equipment, throughputs and energy consumption and other CO2e-relevant emissions.
        <br />   <br />  
        When calculating CO2e values for different countries, the different share of electrical energy and the specific energy mix of the respective country is used. The CO2e value for a country is calculated by using 60 % of the CO2e value of all base elements used and by multiplying the other 40 % with the energy mix of the chosen country. By doing so, sustamize ensures that all base elements have the most common process and that the energy mix is from the most recent year.
      `,
        isOpen: false,
      },
      {
        title: 'POLYMERS',
        content: `The data used for polymer CO2 value calculation is based on studies done on the polymer production. These studies are analyzed and broken down into inputs and processes. sustamize uses CO2e emissions references for the processing of polymers. These references are typically derived from sustamize’s data research process.
        <br /> <br />
        It is important to note that all primary (non-recycling) material calculations are based on the assumption that 100 % of the polymer stem from newly won raw material (also called “virgin material”) or monomers and thus has no recycling material used in production.
        <br />
        ${getImage(PloymersFlowOfTheReferenceModelling, "Polymers - Flow of the Reference Modelling")}
        <br />
        To provide CO2e values for different countries, values from studies are currently being recalculated following a generic sustamize recalculation method according to which 60% of the energy used within the production process is assumed to come from fossil sources while 40% of the energy used comes from electricity used within the process. This will be subject to change in the future when more information on process chains will be implemented.
        <br /> <br />
        This allows for the comparison of adaptation to the different regional energy mixes. The calculation follows the schema portrayed below. Each data point in the sustamize material reference database strictly follows this process schema.
      `,
        isOpen: false,
      },
      {
        title: 'SINTER POWDER',
        content: `Sinter metals follow a similar calculation methodology as the composition within the alloys. Elements are mined, refined, transported and combined for the final sinter metals value. The calculation ends at this level as there aren’t different types of semi-finished forms that would be used as a reshaped base material. `,
        isOpen: false,
      },
      {
        title: 'COMPOSITES',
        content: `Composites are a mix of polymers with fiber material. Data on composites is calculated with basic materials’ factors according to sustamize databases from the relevant materials. These are combined with a factor for the composite’s production. Derived from the processes and global energy mix databases, region-specific values are recalculated (40 %).`,
        isOpen: false,
      },
      {
        title: 'ELEMENTS',
        content: `Base elements’ data is directly extracted from reliable sources and added to the materials database. To distinguish different datasets, a description is often added. sustamize has generally specified four default values. Each value has been classified as a general value that is a good representation of the element if nothing else is known about the production method or origin. In addition, sustamize reports the maximum and minimum value based on various sources as well as an unweighted average for all found values. For elements, there is currently no recycling material.`,
        isOpen: false,
      },
      {
        title: 'CONSTRUCTION',
        content: `The database on construction materials and components for buildings contains various basic materials and semi-finished products relevant for the construction industry. Emission data, for example on different types of cement as well as wood panels can be accessed. Currently, this database is not subject to the usual recalculation and all values are displayed as found in the relevant research.`,
        isOpen: false,
      },
    ],
  },
]
