import { CustomSelectBox } from './CustomSelectBox'
import { CustomDatePicker } from './CustomDatePicker'
import { CustomRangeValueSlider } from './CustomRangeValueSlider'
import CustomCheckbox from './CustomCheckbox'
import CustomInput from './CustomInput'
import {
  alterFilter,
  clearAllFilters,
  removeFilter,
  replaceSelectedFilters,
  selectedFiltersState,
} from 'store/slices/selectedFieldsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import {
  booleanify,
  filterStateToUrlParams,
  getUrlParamValue,
  isListInList,
  urlToObject,
} from 'config/lib'
import { iLooseObject, iSaveSearchParameters } from '../types'
import {
  iCustomField,
  iHubFilter,
  eComponentType,
  eBoundsSliderName,
} from 'store/types'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { RoutePaths } from 'routes/paths'
import { sagaSaveSearchParametersAction } from 'saga/actions/user'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { CustomAutoComplete } from 'components/custom/CustomAutoComplete'
import { sagaSetSettingsDefaultFieldsData } from 'saga/actions/dataHub'

interface iProps {
  filters: iHubFilter[]
  showFiltersOnly?: boolean
  isExpanded?: boolean
  componentType: eComponentType
  handleExpand?: (value: boolean) => void
}

export const DataHubFilterWrapper = ({
  filters,
  showFiltersOnly = false,
  componentType,
}: iProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()

  const location = useLocation()
  const urlPath = location.search
  const chipParams: iLooseObject = urlToObject(urlPath)
  const paramsList = Object.entries(chipParams)

  const searchFiltersState: iCustomField[] =
    useSelector(selectedFiltersState).filters
  const dispatch = useDispatch()
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState<boolean>()
  const [enableApplyFilterButton, setEnableApplyFilterButton] = useState(false)
  const [areAllRequiredFieldsValid, setAreAllRequiredFieldsValid] =
    useState(false)
  const [isAddEnabled, setIsAddEnabled] = useState(false)

  useEffect(() => {
    const newFilters: iCustomField[] = []
    for (const [key, value] of paramsList) {
      const dataHubKeyFilterIndex: number = filters.findIndex(
        (item: iHubFilter) => {
          return item.name === key
        }
      )

      const singleNewFilter: iHubFilter = filters[dataHubKeyFilterIndex]
      const isContextOrSearch = ['contextName', 'terms'].indexOf(key) === -1
      if (isContextOrSearch && singleNewFilter) {
        newFilters.push({
          name: singleNewFilter.name,
          displayName: singleNewFilter.displayName,
          value: value,
          info: singleNewFilter,
        })
      }
    }
    // not on search params page CHECK
    const isSearchParams = location.pathname === RoutePaths.dataHubSearchParams
    if (!isSearchParams) {
      dispatch(replaceSelectedFilters(newFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, location])

  useEffect(() => {
    const isValidFieldIndex = searchFiltersState.findIndex((item) => {
      return item.name === 'isValid'
    })
    const newIsDatePickerDisabled: boolean = booleanify(
      searchFiltersState[isValidFieldIndex]?.value.toString()
    )

    setIsDatePickerDisabled(newIsDatePickerDisabled)
  }, [searchFiltersState, setIsDatePickerDisabled])

  // ** Enable Add Button logic
  // Happens on url change
  useEffect(() => {
    if (pathname !== RoutePaths.dataHubSearchParams) {
      return
    }
    //
    const contextNameTemp: string = getUrlParamValue(chipParams, 'contextName')
    const contextsList: string[] = contextNameTemp.split('.')
    const hasType: boolean = contextsList.length > 2
    const category: string = contextsList[1] || ''
    const hasCategory: boolean = category.length > 0
    let isCategoryEnergy: boolean = category === 'energy'
    let isCategoryElectronics: boolean = category === 'electronics'
    const isCategoryCondition =
      (!hasType && hasCategory && isCategoryEnergy) || isCategoryElectronics

    const isAddEnabled =
      (hasType && areAllRequiredFieldsValid) ||
      (isCategoryCondition && areAllRequiredFieldsValid)
    setIsAddEnabled(isAddEnabled)
  }, [areAllRequiredFieldsValid, chipParams, pathname])

  // check to see if all required fields are completed
  useEffect(() => {
    //this should happend only for search settings
    const isSearchParams = location.pathname === RoutePaths.dataHubSearchParams
    if (!isSearchParams) return

    const requiredFields = filters
      .filter((item) => {
        return item.required === true
      })
      .map((item) => item.name)
    const selectedFields = searchFiltersState.map((item) => item.name)
    setAreAllRequiredFieldsValid(isListInList(selectedFields, requiredFields))
  }, [
    filters,
    location.pathname,
    searchFiltersState,
    setAreAllRequiredFieldsValid,
  ])

  const changeGlobalFilter = useCallback(
    (filter: iCustomField) => {
      if (filter.info.isContext) {
        //Clear all filters values and keys
        dispatch(clearAllFilters())

        // append contextName in url
        const newContext: string = filter.value as string

        setSearchParams({ ...searchParams, contextName: newContext })
      } else {
        if (Array.isArray(filter.value) && filter.value.length === 0) {
          dispatch(removeFilter(filter))
          setEnableApplyFilterButton(true)
        } else {
          dispatch(alterFilter(filter))
          setEnableApplyFilterButton(true)
        }
      }
    },
    [dispatch, searchParams, setSearchParams]
  )
  const getDefaultValue = useCallback(
    (filter: iHubFilter) => {
      //if iscontext, check match context from  url params with values, depending on name of field (type or database)
      if (filter.isContext === true) {
        // does the value from the contextName URL matches the filter values
        const contextName = getUrlParamValue(chipParams, 'contextName')

        const isValueMatch = filter
          ? filter?.values?.indexOf(contextName) !== -1
          : false
        // if it matches, return it
        if (isValueMatch) {
          //if filter values  matches contextName param value, return
          return contextName
        }

        //
        //category check (if type is selected category should be too)
        //

        const splitContext: string[] = getUrlParamValue(
          chipParams,
          'contextName'
        ).split('.')
        // type was selected:
        const isTypeContextSelected =
          chipParams && chipParams['contextName']
            ? splitContext.length === 3
            : false
        if (isTypeContextSelected) {
          const categoryContextValue = `.${splitContext[1]}`
          return categoryContextValue
        }
      }

      //if not, default functionality
      const GlobalStateIndex = searchFiltersState.findIndex(
        (item) => item.name === filter.name
      )
      const globalStateValue =
        GlobalStateIndex !== -1
          ? searchFiltersState[GlobalStateIndex]?.value
          : ''

      const defaultValue =
        searchFiltersState && searchFiltersState.length > 0
          ? globalStateValue
          : ''

      return defaultValue
    },
    [searchFiltersState, chipParams]
  )
  const resetSettingsToDefaults = useCallback(() => {
    dispatch(sagaSetSettingsDefaultFieldsData())
  }, [dispatch])

  // Add new settings functionality

  const onSettingsAddClick = useCallback(() => {
    // make object from array of selected fields (make sure datepickers are not set if isValid === false)
    const splitContext = getUrlParamValue(chipParams, 'contextName').split('.')
    const urmParamsFromEntity = {
      uuid: searchParams.get('uuid'),
      entityTitle: searchParams.get('entityTitle'),
    }
    const tempSettings: iLooseObject = {
      category: splitContext[1],
      type: splitContext[2],
      urmParamsFromEntity,
      ...urmParamsFromEntity,
    }
    const isValidFieldIndex = searchFiltersState.findIndex((item) => {
      return item.name === 'isValid'
    })
    const isValid: boolean =
      searchFiltersState[isValidFieldIndex]?.value === true ||
      // eslint-disable-next-line eqeqeq
      searchFiltersState[isValidFieldIndex]?.value == 'true'
    searchFiltersState.forEach((item) => {
      if (!!isValid) {
        if (['validityEnd', 'validityStart'].indexOf(item.name) !== -1) {
          return
        }
      }
      let key = item.name
      //
      //condition to apply settings search params accourdingly
      //
      // mapping filter key names from data api to body key names for auth api request
      if (location.pathname === RoutePaths.dataHubSearchParams) {
        switch (key) {
          case 'countries':
            if (item && item.value && Array.isArray(item.value)) {
              tempSettings[key] = ''
              tempSettings[key] = item?.value.join(',').toString()
            }
            break
          case 'validityStart':
            tempSettings[key] = dayjs(item.value.toString()).format(
              'YYYY-MM-DD'
            )
            return
          case 'validityEnd':
            tempSettings[key] = dayjs(item.value.toString()).format(
              'YYYY-MM-DD'
            )
            return
          default:
            tempSettings[key] = item.value
            break
        }
      }
    })

    const settingsToBeSaved: iSaveSearchParameters = {
      ...(tempSettings as iSaveSearchParameters),
    }
    const tempContextName = searchParams.get('contextName') || undefined
    dispatch(
      sagaSaveSearchParametersAction(
        settingsToBeSaved,
        tempContextName,
        tempContextName ? setSearchParams : undefined
      )
    )
    // (because I do not want to trigger this use effect when searchParams changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chipParams,
    dispatch,
    location.pathname,
    searchFiltersState,
    setSearchParams,
  ])

  const onApplyFilters = useCallback(() => {
    let newParams: iLooseObject = {
      contextName: searchParams.get('contextName'),
    }
    newParams = filterStateToUrlParams(searchParams, searchFiltersState)
    setEnableApplyFilterButton(false)
    if (searchParams.get('terms')) {
      newParams['terms'] = searchParams.get('terms')
    }
    if (searchParams.get('contextName')) {
      newParams['contextName'] = searchParams.get('contextName')
    }
    setSearchParams(newParams)
    setEnableApplyFilterButton(false)
  }, [searchFiltersState, searchParams, setSearchParams])

  const getFilterName = useCallback(
    (filterNameKey: string) => {
      return t(`filters.${filterNameKey}`)
    },
    [t]
  )

  return (
    <>
      {filters.map((filter: iHubFilter, index: number) => {
        switch (filter.type) {
          case 'array':
            if (filter.isMultiSelect) {
              return (
                <CustomAutoComplete
                  key={`CustomAutocomplete${index}`}
                  displayName={getFilterName(filter.name)}
                  options={filter.values}
                  name={filter.name}
                  defaultValue={getDefaultValue(filter)}
                  filter={filter}
                  onChange={(value: any) => changeGlobalFilter(value)}
                />
              )
            }
            // Check if user is on search params page coming from single entity page - START
            const uuid = searchParams.get('uuid')
            const entityTitle = searchParams.get('entityTitle')
            const uuidExists = uuid && uuid.length > 0
            const entityTitleExists = entityTitle && entityTitle.length > 0
            const isOnRouteSearchParams =
              pathname === RoutePaths.dataHubSearchParams
            const isTypeOrCategory =
              filter.name === 'type' || filter.name === 'database'
            const isSearchParamsFromEntity =
              uuidExists &&
              entityTitleExists &&
              isOnRouteSearchParams &&
              isTypeOrCategory
            // Check if user is on search params page coming from single entity page - END
            return (
              <CustomSelectBox
                isDisabled={isSearchParamsFromEntity || false}
                key={`CustomSelectBox${index}`}
                displayName={getFilterName(filter.name)}
                values={filter.values}
                name={filter.name}
                defaultValue={getDefaultValue(filter)}
                filter={filter}
                onChange={(value) => changeGlobalFilter(value)}
              />
            )
          case 'datetime':
            const disableCondition =
              ['validityStart', 'validityEnd'].indexOf(filter.name) !== -1
                ? isDatePickerDisabled
                : false
            const validityStartIndex = searchFiltersState.findIndex((item) => {
              return item.name === 'validityStart'
            })
            const validityEndIndex = searchFiltersState.findIndex((item) => {
              return item.name === 'validityEnd'
            })
            // MIN Value
            const minValue =
              filter.name === 'validityStart'
                ? undefined
                : (searchFiltersState[validityStartIndex]?.value as string)
            // MAX value
            const maxValue =
              filter.name === 'validityEnd'
                ? undefined
                : (searchFiltersState[validityEndIndex]?.value as string)
            return (
              <CustomDatePicker
                minDate={minValue}
                maxDate={maxValue}
                isHidden={disableCondition}
                key={`CustomDatePicker${index}`}
                displayName={getFilterName(filter.name)}
                value={filter.values}
                name={filter.name}
                defaultValue={getDefaultValue(filter)}
                filter={filter}
                onChange={(value) => changeGlobalFilter(value)}
              />
            )
          case 'range':
            const getBound = (boundName: eBoundsSliderName) => {
              if (filter.bounds !== undefined) {
                const index = filter.bounds.findIndex(
                  (bound) => bound.name === boundName
                )

                if (index !== -1) {
                  return filter.bounds[index].value
                }
              }
            }

            return (
              <CustomRangeValueSlider
                key={`CustomRangeValueSlider${index}`}
                name={filter.name}
                displayName={getFilterName(filter.name)}
                unit={filter.unit}
                defaultValue={getDefaultValue(filter)}
                minValue={getBound(eBoundsSliderName.minValue)}
                maxValue={getBound(eBoundsSliderName.maxValue)}
                filter={filter}
                onChange={(value) => changeGlobalFilter(value)}
              />
            )
          case 'boolean':
            return (
              <span key={`CustomCheckbox${index}`} className="mt-[0.375rem]">
                <CustomCheckbox
                  name={filter.name}
                  displayName={getFilterName(filter.name)}
                  defaultValue={getDefaultValue(filter)}
                  filter={filter}
                  onChange={(innerFilter) => changeGlobalFilter(innerFilter)}
                />
              </span>
            )
          case 'number':
            return (
              <CustomInput
                key={`CustomInput${index}`}
                label={getFilterName(filter.name)}
                unit={filter.unit}
                filter={filter}
                onChange={(innerFilter) => changeGlobalFilter(innerFilter)}
                defaultValue={getDefaultValue(filter)}
              />
            )
          default:
            return null
        }
      })}
      {!showFiltersOnly &&
        (componentType === 'filters' ? (
          <div className="flex justify-end w-full">
            <ButtonWrapper
              disabled={!enableApplyFilterButton}
              onClick={onApplyFilters}
            >
              {t('search.applyFilters')}
            </ButtonWrapper>
          </div>
        ) : (
          <div className="flex w-full justify-end gap-4 mr-4 mb-2">
            <ButtonWrapper onClick={resetSettingsToDefaults}>
              {t('general.cancel')}
            </ButtonWrapper>
            <ButtonWrapper
              disabled={!isAddEnabled}
              onClick={onSettingsAddClick}
            >
              {t('general.add')}
            </ButtonWrapper>
          </div>
        ))}
    </>
  )
}

export default DataHubFilterWrapper
