import DIFFERENTIATOR_TO_OTHER_LCA_DATABASES from 'assets/images/KnowledgeHub/DatabaseOverview/src/pages/KnowledgeCenter/Data/DIFFERENTIATOR_TO_OTHER_LCA_DATABASES.png'
import ReleaseChanges from 'assets/images/KnowledgeHub/DatabaseOverview/src/pages/KnowledgeCenter/Data/ReleaseChanges.png'
import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList } from './helpers'

export const DatabaseOverviewData: IAccordionData[] = [
  {
    title: 'PURPOSE, SCOPE AND USE',
    content:
      "This document describes the current structure of data categories contained in sustamize's Product Footprint Engine (PFE), which is more than just a secondary database. The PFE is accessible via the web-based sustamizer® application, via API Integration and through official partner software providers. <br /> <br /> This document can be used by both internal and external users, in need of a better understanding of the databases' contents and of sustamize's own carefully-developed data calculation method. <br /> <br /> The knowledge hub and the associated reference documents serve exclusively to inform the user about the application possibilities. They do not contain any warranty, guarantee or other assurance regarding the data quality or the functional scope of the sustamizer®. <br /> <br /> For more detailed information on the different databases or the calculation methodology, including quality management, please read the specific sections. In the case you use the PFE's data via partner software solutions and not through the sustamizer® application, please note that our software partners may not include all available CO2 reference databases of the PFE. <br /> <br /> At present, this documentation is exclusively available in English.",
    isOpen: true,
  },
  {
    title: 'RELEASES & CHANGES',
    content:
      'This chapter tracks updates made inside our Product Footprint Engine (Data and API related) as well as the sustamizer front-end (related to features, front-end changes and documentation updates).' +
      getImage(ReleaseChanges),
    noNumberChildren: [
      {
        title: '2.4.0: MINOR 21.05.2024 – Electricity mix update 2023',
        isOpen: true,
        content: `${makeBulttePointsList(
          'Added electricity mix data for the year 2023 for 81 countries (incl. OECD aggregates)'
        )}`,
      },
      {
        title:
          '2.3.1: PATCH 19.04.2024 – Patch update of the Polymers DB; CBAM Table added into Data Hub; Added sids into entity description',
        isOpen: true,
        content: `${makeBulttePointsList(
          'Updated sources of several CO2e values in Polymer DBs, and several values have been updated according to recent research. Moreover, the Polymer DBs was extended for Additive Manufacturing entities (3D printing) | A table covering CO2e reference values coming from the CBAM regulation was added into Knowledge Hub | Entity sids (also called sustamizeId, stands for the sustamize’s unique identification of entities) are now visible on the single entity card in sustamizer (end of description of an entity)'
        )}`,
      },
      {
        title:
          '2.3.0: MINOR 18.12.2023 - General update of sources; Added scope information for Polymers, Elements, Transportation and Packaging CO2e values',
        isOpen: false,
        content: `${makeBulttePointsList(
          'Previously, in some cases, emission factors values were taken from meta studies. As this can give wrong source information about the date and quality of the study, where possible, this was changed to the original study | A scope has been added to the individual data point source information (show more) for the Polymers, Elements, Transportation and Packaging DBs. A description of the different possible scopes has been added to Knowledge Hub: Data Quality, Chapter 3.2 | Emission factors have been added for cooled transportation to Transportation DB'
        )}`,
      },
      {
        title:
          '2.2.1: PATCH 22.11.2023 - External sources fixed, new versioning system implemented',
        content: `${makeBulttePointsList(
          'A bug fixed where worldwatchers or sustamize mentioned as External Source within Material, Manufacturing and Logistics DB | New versioning system introduced in Knowledge Hub Chapter 2, current version shown in the header'
        )}`,
      },
      {
        title: '2.2.0: MINOR 17.10.2023 - Added 500 metals to the Materials DB',
        content: `${makeBulttePointsList(
          'The composition of additional 500 metals was added.'
        )}`,
      },
      {
        title: '2.1.1: PATCH 10.10.2023 - Bug fix in Materials API',
        content: `${makeBulttePointsList(
          "A bug fixed to always fallback to OECD1 as a default value when no country code is specified on Materials API; | A bug in the material API got fixed. If a delivery form is specified, results are now only returned for this delivery form and no longer for both the 'raw' and the selected delivery form; | A bug fixed so that there is always fallback to 'raw' as delivery shape when none is specified."
        )}`,
      },
      {
        title:
          '2.1.0: MINOR 01.08.2023 - Update Energy, Materials, Manufacturing DBs, rework text',
        content: `${makeBulttePointsList(
          'Energy mix update: The 2022 energy mix data is now available for a number of countries. Thus, the following databases have been updated: Energy Mix Database, Manufacturing Database,  Materials Databases; | Electronics DB: PCB were added; | A bug which provided mixed results for country composition was fixed in Materials DB; | A bug leading to wrong results for different shapes in metals was fixed as well as one which led to wrong recycling values resolved in Materials DB; | Knowledge Hub: rework of the section Overview, Data Quality and Energy Databases.'
        )}`,
      },
      {
        title:
          '2.0.0: MAJOR 31.05.2023 - Added Electronics DB, New design of Knowledge Hub',
        content: `${makeBulttePointsList(
          'Launching the Electronic DB  800+ Datapoints added; | New structure and design of Knowledge hub'
        )}`,
      },
      {
        title:
          '1.1.0: MINOR 06.03.2023 - History of electricity data added, resolved a bug for recycling',
        content: `${makeBulttePointsList(
          'Added historic electricity mixed (back to 2016) to the database; | Fixed a bug that led to higher recycling results in some metals than the raw primary result.'
        )}`,
      },
      {
        title:
          '1.0.0: MAJOR 23.01.2023 - Initial database deployment; Go-Live of sustamizer',
        content: `${makeBulttePointsList(
          'Changing from worldwatchers.eu (legacy) to sustamizer.com (including APIs): The materials database calculation methodology underwent a change, potentially leading to larger differences for countries with a higher share of renewable energy. Background: the processes included in the calculation of materials were 100% based on the assumption that the energy used for the process is electricity. sustamize further developed the calculation methodology to include direct emissions in the process, which will be explained in the subsequent sections. Therefore, sustamize decided not to offer historical data, but instead to update all data.'
        )}`,
      },
    ],
  },
  {
    title: 'DIFFERENTIATOR TO OTHER LCA DATABASES',
    content:
      "sustamize's data is dedicated to Product Carbon Footprint (PCF) calculation and differs from data from other providers. <br /> <br />" +
      getImage(DIFFERENTIATOR_TO_OTHER_LCA_DATABASES) +
      ' <br />The usual data modeling procedure can be described as a top-down approach, which means that materials, processes and components are only broken down as far as necessary to achieve a certain level of accuracy, but not down to each individual step.  <br /> <br />    At sustamize, however, a bottom-up approach for value calculation is preferred, which consists in the modelling of every single production step. This ensures a higher level of data quality and enables results to be flexible, hence quickly and easily adaptable to other conditions or to be coordinated with one another. ',
  },
  {
    title: 'DATABASES OVERVIEW',
    content: '',
    children: [
      {
        title: 'ELECTRONICS DATABASES',
        isOpen: true,
        content:
          'The database contains the CO2e emissions [ kg Co2e / kg ] of various electronic components. It includes 41 main classes of electronics, which are then subdivided into 852 additional subclasses of more specific electronic components. The aim is to provide companies with easy access to data that will enable them to get a first impression of products’ carbon footprint and provide them with benchmark values for their calculations. ',
      },
      {
        title: 'Energy Database',
        content:
          'This database includes the CO2e values [ g CO2e / kWh] of the energy mixes of more than 160 countries and regions such as the entire OECD, the EMEA, etc. sustamize has developed this database, composed of two main parts, on the one hand the energy equivalents expressed in CO2e for the different energy sources, including the upstream emissions of the production types, without transfer losses, and on the other hand the current energy source distributions in the different countries. ',
      },
      {
        title: 'LOGISTICS DATABASES',
        content:
          'The logistic databases contains an extensive transportation database and a related packaging database. The transportation database comprises data points for all kinds of private, public and commercial transportation. The calculation result is the amount of CO2 emitted per kilometer and number of tons transported [ g CO2e / tkm]. It is also mentioned if the CO2e is with upstream emissions (well-to-wheel) or just the missions of the transport (tank-to-wheel). <br /> <br /> The packaging database displays carbon emissions [ kg CO2e / different units] related to various packaging materials and products that are used both for intermediate products, transportation as well as final products.',
      },
      {
        title: 'MATERIALS DATABASES',
        content:
          'The PFE on materials contains CO2e data [ kg CO2e / kg ] for different materials (metals, sintered metals, metal powder, plastics, composite materials, organic materials, construction materials). The values for typical semi-finished products for metallic materials are also given in the database. The determination of material CO2 data applied to metals is based on own calculations and direct data sources, like LCA studies. The distinction is made between base materials (elements) and raw materials (element groups), alloys and semi-finished products. The values for alloys are calculated on the basis of the chemical composition (percentages by weight) and the manufacturing process for processing (e.g. ore processing, smelting, alloying) is considered. Aside from the construction materials, all values for materials are available for more than 160 countries. Four different shapes (raw material and granulates, sheets, hollow profiles, solid profiles) are included for metals.',
      },
      {
        title: 'PRODUCTION',
        content:
          'The database on production contains three important reference databases: one for manufacturing processes, one for equipments and one called employees. sustamize has calculated the typical energy values of more than 400 manufacturing [ kg CO2e / kg ] technologies depending on component sizes and/or weight as well as other relevant factors and gathered them in the manufacturing database. The “employees” databases stores over 40 data points related to the activities [ kg CO2e / year] of an average employee. The equipment database [ kg CO2e / Piece ] contains entries for the respective manufacturing processes and production methods.',
      },
    ],
  },
  {
    title: 'ADDITIONAL RESOURCES',
    content: '',
    children: [
      {
        title: 'CBAM (CARBON BORDER ADJUSTMENT MECHANISM) TABLE',
        isOpen: true,
        content:
          'The CBAM table contains the values that were published by the EU for the use of CBAM reporting. They have been taken directly from the source and can be used in the reporting. The lookup has been streamlined so that it is easier to use than the original resource. The CBAM table contains the CN-numbers, names as well as their respective direct, indirect and total emissions.',
      },
    ],
  },
]
