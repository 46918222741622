import { CardContent, Chip, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { iCo2ValuesChipLabel, iCo2Values, eCo2Details } from 'store/types'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { urlToObject, getScopeLabel } from 'config/lib'
import { CardRow } from './CardRow'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { RoutePaths } from 'routes/paths'

interface iProps {
  cO2Values: iCo2Values
  emissionFactorUnitSymbol: string
}

const SingleEntityCard = ({ cO2Values, emissionFactorUnitSymbol }: iProps) => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)

  const processDataLength =
    cO2Values.calculationProcessModel.mathematicalDescription?.length
  const contextName: string = singleEntityParams['contextName'][0]
  const categoryName = contextName.split('.')[1]
  const typeName = contextName.substring(contextName.lastIndexOf('.') + 1)

  const co2Source = cO2Values.co2Source

  const co2Scope = cO2Values.scope
  const isCo2Scope = co2Scope !== null

  const isMaterialContext =
    categoryName === 'materials' &&
    typeName !== 'metals' &&
    processDataLength > 0

  const showChipCondition =
    ['energy', 'production', 'electronics'].indexOf(categoryName) === -1 &&
    ['transportation'].indexOf(typeName) === -1

  const isMetalType = typeName === 'metals'

  const navigate = useNavigate()
  const [chipsIdToDisplay, setChipsIdToDisplay] = useState<number[]>([])
  const [displayChipFlag] = useState(cO2Values.isCommon || cO2Values.isRecycled)

  const chipLabel: iCo2ValuesChipLabel[] = useMemo(
    () => [
      {
        label: t('singleEntity.card.primary'),
        color: '#F1AD5B',
      },
      {
        label: t('singleEntity.card.recycling'),
        color: '#A5EC6B',
      },
    ],
    [t]
  )

  const cardProcessRow = useCallback(() => {
    if (processDataLength) {
      return (
        <CardRow
          label={t('singleEntity.card.process')}
          value={t('general.seeMore')}
          onClick={() => {
            singleEntityParams['id'] = cO2Values['id'] || null
            singleEntityParams['type'] = eCo2Details.process
            delete singleEntityParams['search']
            setSearchParams(singleEntityParams)
          }}
        />
      )
    }
  }, [cO2Values, processDataLength, setSearchParams, singleEntityParams, t])


  const cardSourceRow = useCallback(() => {
    if (co2Source || isMetalType) {
      return (
        <CardRow
          label={t('singleEntity.card.externalSources')}
          value={t('general.seeMore')}
          onClick={() => {
            singleEntityParams['id'] = cO2Values['id'] || null
            singleEntityParams['type'] = eCo2Details.sources
            delete singleEntityParams['search']
            setSearchParams(singleEntityParams)
          }}
        />
      )
    }
  }, [cO2Values, co2Source, isMetalType, setSearchParams, singleEntityParams, t])

  const chipGenerator = useCallback(() => {
    return chipsIdToDisplay.map((chipId, i) => (
      <Chip
        key={`card-chip-${i}`}
        className="w-auto ml-2"
        sx={{ backgroundColor: chipLabel[chipId].color }}
        label={chipLabel[chipId].label}
      />
    ))
  }, [chipLabel, chipsIdToDisplay])

  const navigateToKnowledgeHub = useCallback(
    (knowledgeHubLocation: string) => {
      switch (knowledgeHubLocation) {
        case 'energy':
          navigate(RoutePaths.knowledgeHubEnergy)
          break
        case 'materials':
          navigate(RoutePaths.knowledgeHubMaterials)
          break
        case 'logistics':
          navigate(RoutePaths.knowledgeHubLogistics)
          break
        case 'production':
          navigate(RoutePaths.knowledgeHubProduction)
          break
        case 'electronics':
          navigate(RoutePaths.knowledgeHubElectronics)
          break
        default:
          break
      }
    },
    [navigate]
  )

  useEffect(() => {
    setChipsIdToDisplay([])
    if (showChipCondition) {
      if (cO2Values.isCommon && !cO2Values.isRecycled) {
        setChipsIdToDisplay([0])
      }
      if (cO2Values.isRecycled) {
        setChipsIdToDisplay([1])
      }
    }
  }, [cO2Values, categoryName, showChipCondition, typeName])

  return (
    <Paper
      className="w-[24.75rem] flex flex-col border-2 border-solid"
      elevation={2}
    >
      <CardContent className="px-4 py-1">
        <h6 className="justify-center tracking-[0.14px] pb-3 font-bold text-[22px] leading-[38px] normal-case">
          {cO2Values.referenceValue} {emissionFactorUnitSymbol}
        </h6>
        <div className="flex flex-col">
          <CardRow
            label={t('singleEntity.card.period')}
            value={`${cO2Values.validity.rangeFrom || ''} - 
            ${cO2Values.validity.rangeTo || ''}`}
          />
          <CardRow
            label={t('singleEntity.card.addedOn')}
            value={cO2Values.addedOn}
          />
          {
            isCo2Scope ? (
              <CardRow label={t('singleEntity.card.scope')} value={getScopeLabel(co2Scope)} />
            ) : singleEntityParams['contextName'].includes('.materials.metals') ? (
              <CardRow label={t('singleEntity.card.scope')} value={'Cradle-to-gate'} />
            ) : null
          }
          <CardRow
            label={t('singleEntity.card.mainSource')}
            value={'sustamize GmbH'}
            onClick={() => navigateToKnowledgeHub(contextName.split('.')[1])}
          />
          {/* only show source if exists*/}
          {co2Source
            ? cardSourceRow()
            : isMetalType
            ? cardSourceRow()
            : null}
          {isMaterialContext
            ? cardProcessRow()
            : isMetalType
            ? cardProcessRow()
            : null}
          <div className="row flex justify-end mb-2 -mr-1">
            {displayChipFlag ? chipGenerator() : ''}
          </div>
        </div>
      </CardContent>
    </Paper>
  )
}

export default SingleEntityCard
