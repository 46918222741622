import React from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DataWithLabel from './DataWithLabel'

import './SingleEntityDetails.css'
import { iCo2Process } from 'store/types'

interface iProps {
  data: iCo2Process
}

const SingleEntityCo2ProcessDetails = ({ data }: iProps) => {
  const { t } = useTranslation()
  return (
    <Paper className="min-h-[200px] p-4 mx-4 mb-4">
      <div className="mb-3 p-2">
        <DataWithLabel
          label={t('singleEntity.details.description')}
          data={data.mathematicalDescription}
        />
      </div>
    </Paper>
  )
}

export default SingleEntityCo2ProcessDetails
