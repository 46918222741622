import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { iSingleEntityInstanceData } from 'store/types'
import TextWrapper from 'components/custom/TextWrapper'

interface iProps {
  entity?: iSingleEntityInstanceData
}

const SingleEntityBaseInformation = ({ entity }: iProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        ':first-of-type': {
          borderRadius: '1rem',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-base-information-content"
        id="panel-base-information-content"
      >
        <Typography variant="h4" fontSize="1.6rem" fontWeight="500">
          {t('singleEntity.baseInformation.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextWrapper>
          {entity?.information?.identification?.description +
            ' | ' +
            'sid: ' +
            entity?.information?.identification?.sid}
        </TextWrapper>
      </AccordionDetails>
    </Accordion>
  )
}

export default SingleEntityBaseInformation
