import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { iCo2Values } from 'store/types'
import { useLocation } from 'react-router-dom'
import { getUrlParamValue, urlToObject } from 'config/lib'
import { eCo2Details, iCo2Process, iCo2Source } from 'store/types/singleEntity'

import './SingleEntityDetails.css'
import SingleEntityCo2SourceDetails from './SingleEntityCo2SourceDetails'
import { susIcons } from 'config/services/requests'
import { iLooseObject } from 'pages/DataHub/filters/types'
import SingleEntityCo2ProcessDetails from './SingleEntityCo2ProcessDetails'

interface iProps {
  cO2Values: iCo2Values[]
}
const SingleEntityDetails = ({ cO2Values }: iProps) => {
  const location = useLocation()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)
  const dataIndex = cO2Values.findIndex((item: iCo2Values, index: number) => {
    return item.id === getUrlParamValue(singleEntityParams, 'id')
  })
  const data: any = cO2Values[dataIndex] // data variable exposes the data for this component
  const contextName: string = singleEntityParams['contextName'][0]
  const contextType = contextName.substring(contextName.lastIndexOf('.') + 1)

  const paramsType: string = getUrlParamValue(singleEntityParams, 'type')
  const typeCheck = paramsType

  const { t } = useTranslation()

  const DataPointIcon = susIcons.DataPoints

  if (typeCheck === eCo2Details.process) {
    return (
      <SingleEntityCo2ProcessDetails
        data={data.calculationProcessModel as iCo2Process}
      />
    )
  }
  if (typeCheck === eCo2Details.sources && contextType !== 'metals') {
    const co2Sources = []

    co2Sources.push(data.co2Source)
    return <SingleEntityCo2SourceDetails data={co2Sources as iCo2Source[]} />
  }

  if (typeCheck === eCo2Details.sources && contextType === 'metals') {
    if (data.co2ShareSources !== undefined)
      return (
        <SingleEntityCo2SourceDetails
          data={data.co2ShareSources as iCo2Source[]}
        />
      )
    else {
      const co2Sources = []
      co2Sources.push(data.co2Source)
      return <SingleEntityCo2SourceDetails data={co2Sources as iCo2Source[]} />
    }
  }

  return (
    <div className="singleEntity-dialog-wrapper">
      <Typography variant="h4">{t('general.error')}</Typography>
      <DataPointIcon />
    </div>
  )
}

export default SingleEntityDetails
