import { iContextRequestResult, tExcelWorkbookCollection, tFileContextCollection, iContextItem, iContextMetal, iContextPolymer } from "store/types/massFileUpload";
import ExcelJS from 'exceljs';
import { getWorksheetNameFromContextName } from "./helpers";

/**
 * Inject the data retrieved from the search API into the worksheet object
 * and return the newly created worksheet
 * @param fileName The name of the file to be processed
 * @param workbooks The collection of ExcelJS workbook objects
 * @param contexts The contexts (worksheets) for each file as objects
 * @returns The Excel workbook object, containing the information retrieved
 * from the search API
 */
export const injectApiDataIntoWorkbook = (
  fileName: string,
  workbooks: tExcelWorkbookCollection,
  contexts: tFileContextCollection
): ExcelJS.Workbook => {

  let workbook = workbooks[fileName];
  let context = contexts[fileName];

  Object.entries(context).forEach(([contextName, contextItems]) => {

    /** Obtain the worksheet */
    let worksheetName = getWorksheetNameFromContextName(contextName);

    if (!worksheetName) 
      throw new Error(`Worksheet name could not be obtained from API context name`);

    let worksheet = workbook.getWorksheet(worksheetName);

    /** Inject the context values */
    contextItems.forEach(_contextItem => {

      /** Determine the proper value, first */
      let contextKeys = Object.keys((_contextItem as any));
      let hasDeliveryShape: number = 0;
      let hasReferenceType: number = 0;
      if (contextKeys.includes(`deliveryShape`)) hasDeliveryShape = 1;
      if (contextKeys.includes(`referenceType`)) hasReferenceType = 1;

      /** Set the proper object type */
      let contextItem: 
        iContextMetal&iContextRequestResult
        |iContextPolymer&iContextRequestResult
        |iContextItem&iContextRequestResult;

      if (hasDeliveryShape && hasReferenceType) {
        contextItem = _contextItem as (iContextMetal&iContextRequestResult);
      } else if (hasReferenceType) {
        contextItem = _contextItem as (iContextPolymer&iContextRequestResult);
      } else {
        contextItem = _contextItem as (iContextItem&iContextRequestResult);
      }

      /** Select row and cells */
      let row = worksheet.getRow(contextItem.rowNumber);
      let co2Cell = row.getCell(4 + hasDeliveryShape + hasReferenceType);
      let descriptionCell = row.getCell(5 + hasDeliveryShape + hasReferenceType);

      /** Inject the values */
      let referenceTypeKey = (contextItem as iContextPolymer)
        .referenceType?.toLowerCase() || 'common';

      co2Cell.value = (contextItem.co2Emission as any)[referenceTypeKey];
      descriptionCell.value = contextItem.description;
    });
  });

  return workbook;
}

/**
 * Downloads a particular file from the workbook collection
 * @param fileName The name of the file to be downloaded
 * @param collection The collection containing all workbook objects
 */
export const downloadProcessedFile = (
  fileName: string,
  workbook: ExcelJS.Workbook
) => {

  const timestamp = new Date().toISOString();
  const newFileName = `${fileName}-processed-${timestamp}.xlsx`;
  const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  workbook.xlsx.writeBuffer()
      .then((data) => {
          const blob = new Blob([data], {
              type: mimeType
          })
          downloadFile(blob, newFileName);
      })
      .catch((error) => {
          console.error(error);
      })
}

/**
 * Sending a download signal to the browser for downloading a blob
 * @param blob The binary blob to be downloaded
 * @param fileName The name of the file to be downloaded
 */
const downloadFile = (blob: Blob, fileName: string) => {
  let a;
  let url = window.URL.createObjectURL(blob);

  a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);

  (a as any).style = 'display: none';
  a.click();
  a.remove();

  window.URL.revokeObjectURL(url);
}