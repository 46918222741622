import ExcelJS, { CellValue } from 'exceljs'
import { iSingleEntity } from 'store/types'
import {
  iContextRequestResult,
  eDeliveryShape,
  iContextItem,
  iContextMetal,
  iContextPolymer,
  eReferenceType,
} from 'store/types/massFileUpload'
import {
  CellErrorValue,
  CellRichTextValue,
  CellHyperlinkValue,
  CellFormulaValue,
  CellSharedFormulaValue,
} from 'exceljs'

/**
 * Converts to proper isRecycled value
 * @param val Cell value from an ExcelJS Workbook
 * @returns The proper conversion of the boolean isRecycled
 */
export const isRecycled = (val?: ExcelJS.CellValue) => {
  val = handleCellValue(val, `primary`, [stringToLowerCase])

  let falsyConditions =
    val === '' || val.includes('false') || val.includes('primary')
  let truthyConditions = val.includes('recycled') || val.includes('true')

  if (falsyConditions) return false
  if (truthyConditions) return true
}

/**
 * Properly builds the search terms for the API Search request
 * @param terms The search terms coming from the Excel sheet
 * @returns Either an array of strings or a single string
 */
export const buildSearchTerms = (...terms: string[]): string | string[] => {
  let searchTerms: string[] = []

  terms.forEach((term) => {
    /** If not proper format, do nothing. Else push to return value array. */
    const isValid =
      term !== null ||
      term !== undefined ||
      term !== '' ||
      typeof term === 'string'

    if (isValid) searchTerms.push(term)
  })

  return searchTerms.length === 1 ? searchTerms[0] : searchTerms
}

/**
 * A helper for building output contexts that will be used to construct the
 * final Excel output file.
 * @param entity The single entity, retrieved from the search API
 * @param contextItem The context item, obtained from the input Excel file
 */
export const buildContextRequestResults = (
  entity: iSingleEntity,
  contextItem: iContextItem | iContextMetal | iContextPolymer
):
  | (iContextItem & iContextRequestResult)
  | (iContextMetal & iContextRequestResult)
  | (iContextPolymer & iContextRequestResult) => {
  /** Basic context object */
  let entityInformation: iContextRequestResult = {
    co2Emission: contextItem?.isRecycled
      ? entity?.entity?.instance?.co2Emission[0]?.co2Recycling
      : entity?.entity?.instance?.co2Emission[0]?.co2Base,
    description:
      entity?.entity?.instance?.information?.identification?.description,
  }

  /** Handle diverging type bounds */
  let referenceType: eReferenceType | undefined
  let deliveryShape: eDeliveryShape | undefined

  if (Object.keys(contextItem).includes('referenceType'))
    referenceType = (contextItem as iContextPolymer)?.referenceType
  if (Object.keys(contextItem).includes('deliveryShape'))
    deliveryShape = (contextItem as iContextMetal)?.deliveryShape

  /** Build the final object based on its type */
  switch (true) {
    /** Metal */
    case referenceType !== undefined && deliveryShape !== undefined:
      return {
        ...contextItem,
        ...entityInformation,
        referenceType: referenceType,
        deliveryShape: deliveryShape,
      }
    /** Polymer */
    case referenceType !== undefined && deliveryShape === undefined:
      return {
        ...contextItem,
        ...entityInformation,
        referenceType: referenceType,
      }
    /** Composites / Other */
    default:
      return {
        ...contextItem,
        ...entityInformation,
      }
  }
}

/**
 * Retrieves the API context name, based on the worksheet name
 * @param ws The Excel worksheet extracted from the Excel workbook
 * @returns The API context name
 */
export const getContextNameFromWorksheet = (ws: ExcelJS.Worksheet): string => {
  let name = ws.name.toLowerCase()

  switch (true) {
    case name === 'metals':
      return '.materials.metals'
    case name === 'polymers':
      return '.materials.polymers'
    case name === 'composites':
      return '.materials.composites'
    case name === 'sinters':
      return '.materials.sinters'
    case name === 'elements':
      return '.materials.elements'
    case name === 'construction':
      return '.materials.construction'
    default:
      return '.materials'
  }
}

/**
 * Retrieves a worksheet name from an API context name
 * @param contextName The name of the context (e.g. .materials.polymers)
 * @returns The last part of the string (tab name)
 */
export const getWorksheetNameFromContextName = (
  contextName: string
): string | undefined => {
  let match = contextName.match(/[a-z]+$/)
  return match === null ? undefined : capitalizeFirst(match[0])
}

/**
 * Capitalizes the first character of a string
 * @param str The string to capitalize the first letter for
 * @returns The same string, with the first char capitalized
 */
export const capitalizeFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * A helper to handle the cell values coming from the Excel worksheet
 * @param value The value coming from the cell of the Excel worksheet
 * @param fallback The value the cell value should fall back on (optional)
 * @param operations A list of operations to be performed on the string
 * @returns
 */
export const handleCellValue = (
  value?: CellValue,
  fallback?: string,
  operations?: ((val: any) => string)[]
): string => {
  /** Type Guard Helpers */
  const cellValueIsError = (value: CellValue): value is CellErrorValue => {
    return Boolean(Object.keys(value as any).includes('error'))
  }
  const cellValueIsRichText = (
    value: CellValue
  ): value is CellRichTextValue => {
    return Boolean(Object.keys(value as any).includes('richText'))
  }
  const cellValueIsHyperlink = (
    value: CellValue
  ): value is CellHyperlinkValue => {
    return Boolean(Object.keys(value as any).includes('hyperlink'))
  }
  const cellValueIsFormula = (value: CellValue): value is CellFormulaValue => {
    return Boolean(Object.keys(value as any).includes('formula'))
  }
  const cellValueIsSharedFormula = (
    value: CellValue
  ): value is CellSharedFormulaValue => {
    return Boolean(Object.keys(value as any).includes('sharedFormula'))
  }

  /** Catch cases */
  if (!value && typeof fallback === 'string') return fallback as string // unsafe
  if (!value && !fallback) return ''
  if (value && cellValueIsError(value)) return ''
  if (value && cellValueIsRichText(value)) return ''
  if (value && cellValueIsHyperlink(value)) return ''
  if (value && cellValueIsFormula(value)) return ''
  if (value && cellValueIsSharedFormula(value)) return ''

  /** If operation needs to be performed */
  if (Array.isArray(operations) && operations.length > 0) {
    operations.forEach((fn) => {
      try {
        let newValueAfterOperation = fn(value)

        if (!newValueAfterOperation)
          throw Error(`Could not apply operation to string.`)
        else value = newValueAfterOperation
      } catch (e) {
        console.error(`Could not perform cell handler function: ${e}`)
      }
    })
  }

  return !value ? '' : value.toString()
}

/**
 * Rewired function, replacing the string.prototype to lowercase function
 * @param str String to be set to lowercase
 * @returns String to lowercase
 */
export const stringToLowerCase = (str: string): string => {
  return str.toLowerCase()
}

/**
 * Function to strip spaces from a string
 * @param str String to strip spaces from
 * @returns A string, free of spaces
 */
export const stripSpaces = (str: string): string => {
  return str.replace(/\s+/g, '')
}
